const hello = `begin
    var i := 0;
    while i < 10 do
    begin
        output("Hello, World!\\n");
        i := i + 1;
    end
end
`;

export default hello;
