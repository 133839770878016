"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SymbolTable = void 0;
class SymbolTable {
    constructor() {
        this.scopes = [];
        this.names = new Map();
    }
    reset() {
        this.scopes = [];
        this.names = new Map();
    }
    addScope() {
        this.scopes.push(new Set());
    }
    setSymbol(name, value) {
        const currentScope = this.scopes[this.scopes.length - 1];
        if (currentScope.has(name)) {
            const vars = this.names.get(name);
            if (vars === undefined) {
                throw new Error(`invalid state, current scope has symbol "${name}" but names don't have it`);
            }
            vars[vars.length - 1] = value;
        }
        else {
            if (!this.names.has(name)) {
                this.names.set(name, []);
            }
            this.names.get(name).push(value);
            currentScope.add(name);
        }
    }
    getSymbol(name) {
        const variable = this.names.get(name);
        if (variable === undefined) {
            throw new Error(`invalid state, searching undefined symbol "${name}"`);
        }
        return variable[variable.length - 1];
    }
    popScope() {
        const poppedSymbols = this.scopes.pop();
        if (poppedSymbols === undefined) {
            return;
        }
        for (const sym of poppedSymbols) {
            const values = this.names.get(sym);
            if (values === undefined) {
                throw new Error(`invalid state, current poppedSymbols has symbol "${sym}" but names don't have it`);
            }
            values.pop();
            if (values.length === 0) {
                this.names.delete(sym);
            }
        }
    }
}
exports.SymbolTable = SymbolTable;
