"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.analyze = void 0;
const api_1 = require("./api");
const ast_1 = require("./ast");
const errors_1 = require("./errors");
const semantic_1 = require("./semantic");
const tokens_1 = require("./tokens");
function analyze(ast, builtins = api_1.apis) {
    const ctx = new Context();
    ctx.addScope();
    const errors = [];
    const functions = [...builtins.functions];
    for (const func of builtins.functions) {
        ctx.addBuiltinSymbol(func.name, func.type);
    }
    for (const func of ast.functions) {
        const typeResult = analyzeFuncType(ctx, func);
        if (typeResult.err !== undefined) {
            errors.push(typeResult.err);
            continue;
        }
        const result = ctx.addSymbol(func.name, typeResult.ok);
        if (result.err !== undefined) {
            errors.push(result.err);
        }
    }
    const variables = [];
    for (const variable of ast.variables) {
        const varResult = analyzeVariable(ctx, variable);
        if (varResult.err !== undefined) {
            errors.push(varResult.err);
            continue;
        }
        const result = ctx.addSymbol(variable.name, varResult.ok.type);
        if (result.err !== undefined) {
            errors.push(result.err);
            continue;
        }
        variables.push(varResult.ok);
    }
    for (const func of ast.functions) {
        const funcResult = analyzeFunction(ctx, func);
        if (funcResult.err !== undefined) {
            errors.push(funcResult.err);
            continue;
        }
        functions.push(funcResult.ok);
    }
    let main;
    if (ast.main === undefined) {
        errors.push(new errors_1.MissingMain());
    }
    else {
        const result = analyzeBlockStatement(ctx, ast.main.body);
        if (result.err !== undefined) {
            errors.push(result.err);
        }
        else {
            main = result.ok;
        }
    }
    if (errors.length > 0) {
        throw new errors_1.MultiCompileError(errors);
    }
    return { globals: variables, functions, main: main };
}
exports.analyze = analyze;
class Context {
    constructor() {
        this.scopes = [];
        this.loopDepth = 0;
        this.currentReturnType = { kind: semantic_1.TypeKind.VOID };
    }
    get(name) {
        for (let i = this.scopes.length - 1; i >= 0; i--) {
            if (name in this.scopes[i]) {
                return this.scopes[i][name];
            }
        }
    }
    getInScope(name) {
        if (name in this.scopes[this.scopes.length - 1]) {
            return this.scopes[this.scopes.length - 1][name];
        }
        return undefined;
    }
    addSymbol(token, type) {
        const sym = this.scopes[this.scopes.length - 1][token.value];
        if (sym !== undefined) {
            if (sym.isBuiltin) {
                return err(new errors_1.BuiltinRedeclared(token));
            }
            else {
                return err(new errors_1.MultipleDeclaration(sym.name, token));
            }
        }
        this.scopes[this.scopes.length - 1][token.value] = {
            name: token,
            isBuiltin: false,
            type,
        };
        return ok(undefined);
    }
    addBuiltinSymbol(name, type) {
        if (name in this.scopes[this.scopes.length - 1]) {
            throw new Error(`Builtin ${name} is redeclared`);
        }
        this.scopes[this.scopes.length - 1][name] = { name, isBuiltin: true, type };
    }
    addScope() {
        this.scopes.push({});
    }
    popScope() {
        this.scopes.pop();
    }
}
function ok(value) {
    return { ok: value, err: undefined };
}
function err(err) {
    return { ok: undefined, err };
}
function analyzeVariable(ctx, variable, constantOnly = false) {
    assertTokenKind(variable.name, tokens_1.TokenKind.IDENTIFIER);
    const name = variable.name.value;
    assert(variable.type !== undefined || variable.value !== undefined, 'variable declaration should have type or value expression');
    let value;
    if (variable.value !== undefined) {
        const exprResult = analyzeExpr(ctx, variable.value);
        if (exprResult.err !== undefined) {
            return err(exprResult.err);
        }
        value = exprResult.ok;
    }
    let type = value === null || value === void 0 ? void 0 : value.type;
    if (type === undefined) {
        const typeResult = analyzeType(ctx, variable.type);
        if (typeResult.err !== undefined) {
            return err(typeResult.err);
        }
        type = typeResult.ok;
    }
    assert(type !== undefined, 'variable declaration should have type or value expression');
    if (value !== undefined) {
        if (!typeEqual(value.type, type)) {
            return err(new errors_1.TypeMismatch(value, type));
        }
        if (constantOnly && !value.isConstexpr) {
            return err(new errors_1.NotAConstant(value));
        }
    }
    return ok({ name, type, value });
}
function analyzeFunction(ctx, func) {
    assertTokenKind(func.name, tokens_1.TokenKind.IDENTIFIER);
    const name = func.name.value;
    const symbol = ctx.get(name);
    if (symbol === undefined) {
        throw new Error(`function type for "${name}" is not found`);
    }
    if (symbol.type.kind !== semantic_1.TypeKind.FUNCTION) {
        throw new Error(`symbol ${name} is not a function`);
    }
    ctx.currentReturnType = symbol.type.return;
    if (symbol.type.arguments.length !== func.params.params.length) {
        throw new Error(`unmatched parameters length in function ${name}`);
    }
    const params = symbol.type.arguments.map((type, i) => ({
        name: func.params.params[i].name,
        isBuiltin: false,
        type,
    }));
    const paramNames = func.params.params.map((v) => v.name.value);
    let body;
    if (func.body !== undefined) {
        const result = analyzeBlockStatement(ctx, func.body, params);
        if (result.err !== undefined) {
            return err(result.err);
        }
        body = result.ok;
    }
    return ok({
        name,
        type: symbol.type,
        arguments: paramNames,
        body,
    });
}
function analyzeStatement(ctx, stmt) {
    switch (stmt.kind) {
        case ast_1.StatementNodeKind.BLOCK:
            return analyzeBlockStatement(ctx, stmt);
        case ast_1.StatementNodeKind.VAR:
            return analyzeVarStatement(ctx, stmt);
        case ast_1.StatementNodeKind.ASSIGN:
            return analyzeAssignStatement(ctx, stmt);
        case ast_1.StatementNodeKind.EXPR:
            return analyzeExprStatement(ctx, stmt);
        case ast_1.StatementNodeKind.IF:
            return analyzeIfStatement(ctx, stmt);
        case ast_1.StatementNodeKind.WHILE:
            return analyzeWhileStatement(ctx, stmt);
        case ast_1.StatementNodeKind.RETURN:
            return analyzeReturnStatement(ctx, stmt);
        case ast_1.StatementNodeKind.KEYWORD:
            return analyzeKeywordStatement(ctx, stmt);
        default:
            throw new Error(`unrecognized statement kind`);
    }
}
function analyzeBlockStatement(ctx, stmt, additionalSymbols = []) {
    ctx.addScope();
    for (const symbol of additionalSymbols) {
        if (symbol.isBuiltin) {
            throw new Error(`Cannot add builtin symbol "${symbol.name}"`);
        }
        ctx.addSymbol(symbol.name, symbol.type);
    }
    const errors = [];
    const statements = [];
    for (const item of stmt.statements) {
        const result = analyzeStatement(ctx, item);
        if (result.err !== undefined) {
            errors.push(result.err);
            continue;
        }
        statements.push(result.ok);
    }
    ctx.popScope();
    if (errors.length > 0) {
        return err(new errors_1.MultiCompileError(errors));
    }
    return ok({ kind: semantic_1.StatementKind.BLOCK, body: statements });
}
function analyzeVarStatement(ctx, stmt) {
    const result = analyzeVariable(ctx, stmt.variable, false);
    if (result.err !== undefined) {
        return err(result.err);
    }
    ctx.addSymbol(stmt.variable.name, result.ok.type);
    return ok({ kind: semantic_1.StatementKind.VAR, variable: result.ok });
}
function analyzeAssignStatement(ctx, stmt) {
    const receiverResult = analyzeExpr(ctx, stmt.receiver);
    if (receiverResult.err !== undefined) {
        return err(receiverResult.err);
    }
    const receiver = receiverResult.ok;
    if (!receiver.isAssignable) {
        return err(new errors_1.NotAssignable(receiver));
    }
    const valueResult = analyzeExpr(ctx, stmt.value);
    if (valueResult.err !== undefined) {
        return err(valueResult.err);
    }
    const value = valueResult.ok;
    if (!typeEqual(value.type, receiver.type)) {
        return err(new errors_1.TypeMismatch(value, receiver.type));
    }
    return ok({
        kind: semantic_1.StatementKind.ASSIGN,
        target: receiver,
        value,
    });
}
function analyzeExprStatement(ctx, stmt) {
    const result = analyzeExpr(ctx, stmt.expr);
    if (result.err !== undefined) {
        return err(result.err);
    }
    return ok({ kind: semantic_1.StatementKind.EXPR, value: result.ok });
}
function analyzeIfStatement(ctx, stmt) {
    const conditionResult = analyzeExpr(ctx, stmt.condition);
    if (conditionResult.err !== undefined) {
        return err(conditionResult.err);
    }
    const condition = conditionResult.ok;
    if (!typeEqual(condition.type, semantic_1.Boolean)) {
        return err(new errors_1.TypeMismatch(condition, semantic_1.Boolean));
    }
    const bodyResult = analyzeStatement(ctx, stmt.body);
    if (bodyResult.err !== undefined) {
        return err(bodyResult.err);
    }
    const body = bodyResult.ok;
    let elseStmt;
    if (stmt.elseBody !== undefined) {
        const result = analyzeStatement(ctx, stmt.elseBody);
        if (result.err !== undefined) {
            return err(result.err);
        }
        elseStmt = result.ok;
    }
    return ok({
        kind: semantic_1.StatementKind.IF,
        condition,
        body,
        else: elseStmt,
    });
}
function analyzeWhileStatement(ctx, stmt) {
    const conditionResult = analyzeExpr(ctx, stmt.condition);
    if (conditionResult.err !== undefined) {
        return err(conditionResult.err);
    }
    const condition = conditionResult.ok;
    if (!typeEqual(condition.type, semantic_1.Boolean)) {
        return err(new errors_1.TypeMismatch(condition, semantic_1.Boolean));
    }
    ctx.loopDepth++;
    const bodyResult = analyzeStatement(ctx, stmt.body);
    if (bodyResult.err !== undefined) {
        ctx.loopDepth--;
        return err(bodyResult.err);
    }
    const body = bodyResult.ok;
    ctx.loopDepth--;
    return ok({
        kind: semantic_1.StatementKind.WHILE,
        condition,
        body,
    });
}
function analyzeReturnStatement(ctx, stmt) {
    if (stmt.value != null) {
        const valueResult = analyzeExpr(ctx, stmt.value);
        if (valueResult.err !== undefined) {
            return err(valueResult.err);
        }
        const value = valueResult.ok;
        if (!typeEqual(value.type, ctx.currentReturnType)) {
            return err(new errors_1.TypeMismatch(value, ctx.currentReturnType));
        }
        return ok({ kind: semantic_1.StatementKind.RETURN, value });
    }
    else {
        if (ctx.currentReturnType.kind !== semantic_1.TypeKind.VOID) {
            return err(new errors_1.MissingReturnValue(stmt.return));
        }
        return ok({ kind: semantic_1.StatementKind.RETURN });
    }
}
function analyzeKeywordStatement(ctx, stmt) {
    const keyword = stmt.keyword.kind;
    let kind;
    if (keyword === tokens_1.TokenKind.CONTINUE) {
        kind = semantic_1.StatementKind.CONTINUE;
    }
    else if (keyword === tokens_1.TokenKind.BREAK) {
        kind = semantic_1.StatementKind.BREAK;
    }
    else {
        throw new Error(`keyword statement "${keyword}" is not supported yet`);
    }
    if (ctx.loopDepth === 0) {
        return err(new errors_1.NotInALoop(stmt.keyword));
    }
    return ok({ kind });
}
function analyzeExpr(ctx, expr) {
    switch (expr.kind) {
        case ast_1.ExprNodeKind.IDENT:
            return analyzeIdentExpr(ctx, expr);
        case ast_1.ExprNodeKind.INTEGER_LIT:
            return analyzeIntegerLitExpr(expr);
        case ast_1.ExprNodeKind.BOOLEAN_LIT:
            return analyzeBooleanLitExpr(expr);
        case ast_1.ExprNodeKind.ARRAY_LIT:
            return analyzeArrayLitExpr(ctx, expr);
        case ast_1.ExprNodeKind.STRING_LIT:
            return analyzeStringLitExpr(expr);
        case ast_1.ExprNodeKind.REAL_LIT:
            return analyzeRealLitExpr(expr);
        case ast_1.ExprNodeKind.BINARY:
            return analyzeBinaryExpr(ctx, expr);
        case ast_1.ExprNodeKind.UNARY:
            return analyzeUnaryExpr(ctx, expr);
        case ast_1.ExprNodeKind.CALL:
            return analyzeCallExpr(ctx, expr);
        case ast_1.ExprNodeKind.ARRAY_INDEX:
            return analyzeArrayIndexExpr(ctx, expr);
        case ast_1.ExprNodeKind.CAST:
            return analyzeCastExpr(ctx, expr);
        case ast_1.ExprNodeKind.GROUPED:
            return analyzeExpr(ctx, expr.value);
        default:
            throw new Error(`unrecognized expr kind`);
    }
}
function analyzeIdentExpr(ctx, expr) {
    assertTokenKind(expr.name, tokens_1.TokenKind.IDENTIFIER);
    const name = expr.name.value;
    const symbol = ctx.get(name);
    if (symbol === undefined) {
        return err(new errors_1.UndefinedSymbol(expr.name));
    }
    return ok({
        kind: semantic_1.ExprKind.IDENT,
        type: symbol.type,
        isConstexpr: false,
        constValue: undefined,
        isAssignable: true,
        position: expr.name.position,
        ident: name,
    });
}
function analyzeIntegerLitExpr(expr) {
    assertTokenKind(expr.value, tokens_1.TokenKind.INTEGER_LITERAL);
    const value = BigInt.asIntN(64, BigInt(expr.value.value));
    return ok({
        kind: semantic_1.ExprKind.INTEGER_LIT,
        type: semantic_1.Integer,
        isConstexpr: true,
        constValue: value,
        isAssignable: false,
        position: expr.value.position,
        value,
    });
}
function analyzeBooleanLitExpr(expr) {
    let value;
    if (expr.value.kind === tokens_1.TokenKind.TRUE) {
        value = true;
    }
    else if (expr.value.kind === tokens_1.TokenKind.FALSE) {
        value = false;
    }
    else {
        throw new Error('boolean expression should have true or false value');
    }
    return ok({
        kind: semantic_1.ExprKind.BOOLEAN_LIT,
        type: semantic_1.Boolean,
        isConstexpr: true,
        constValue: value,
        isAssignable: false,
        position: expr.value.position,
        value,
    });
}
function analyzeArrayLitExpr(ctx, expr) {
    const values = [];
    let isConstexpr = true;
    const constValue = [];
    for (let i = 0; i < expr.value.values.length; i++) {
        const valNode = expr.value.values[i];
        const result = analyzeExpr(ctx, valNode);
        if (result.err !== undefined) {
            return err(result.err);
        }
        const value = result.ok;
        isConstexpr = isConstexpr && value.isConstexpr;
        constValue.push(value.constValue);
        values.push(value);
    }
    for (let i = 1; i < values.length; i++) {
        if (!typeEqual(values[i].type, values[0].type)) {
            return err(new errors_1.TypeMismatch(values[i], values[0].type));
        }
    }
    let elementType = values[0].type;
    let dimension = [BigInt.asIntN(64, BigInt(values.length))];
    if (values[0].type.kind === semantic_1.TypeKind.ARRAY) {
        elementType = values[0].type.elementType;
        dimension = [dimension[0], ...values[0].type.dimension];
    }
    const typ = {
        kind: semantic_1.TypeKind.ARRAY,
        dimension,
        elementType,
    };
    return ok({
        kind: semantic_1.ExprKind.ARRAY_LIT,
        isConstexpr,
        constValue: isConstexpr ? constValue : undefined,
        isAssignable: false,
        position: expr.openSquare.position,
        type: typ,
        values,
    });
}
function analyzeStringLitExpr(expr) {
    const trimQuote = expr.value.value.slice(1, expr.value.value.length - 1);
    return ok({
        kind: semantic_1.ExprKind.STRING_LIT,
        isConstexpr: true,
        constValue: trimQuote,
        isAssignable: false,
        type: { kind: semantic_1.TypeKind.STRING },
        position: expr.value.position,
        value: trimQuote,
    });
}
function analyzeRealLitExpr(expr) {
    assertTokenKind(expr.value, tokens_1.TokenKind.REAL_LITERAL);
    const value = Number(expr.value);
    return ok({
        kind: semantic_1.ExprKind.REAL_LIT,
        type: semantic_1.Integer,
        isConstexpr: true,
        constValue: value,
        isAssignable: false,
        position: expr.value.position,
        value,
    });
}
const integerBinOp = new Set([
    tokens_1.TokenKind.PLUS,
    tokens_1.TokenKind.MINUS,
    tokens_1.TokenKind.MULTIPLY,
    tokens_1.TokenKind.DIV,
    tokens_1.TokenKind.MOD,
    tokens_1.TokenKind.SHIFT_LEFT,
    tokens_1.TokenKind.SHIFT_RIGHT,
    tokens_1.TokenKind.BIT_AND,
    tokens_1.TokenKind.BIT_OR,
    tokens_1.TokenKind.BIT_XOR,
]);
const realBinOp = new Set([
    tokens_1.TokenKind.PLUS,
    tokens_1.TokenKind.MINUS,
    tokens_1.TokenKind.MULTIPLY,
    tokens_1.TokenKind.DIV,
]);
const compBinOp = new Set([
    tokens_1.TokenKind.GREATER_THAN,
    tokens_1.TokenKind.GREATER_THAN_EQUAL,
    tokens_1.TokenKind.LESS_THAN,
    tokens_1.TokenKind.LESS_THAN_EQUAL,
    tokens_1.TokenKind.EQUAL,
    tokens_1.TokenKind.NOT_EQUAL,
]);
const boolBinOp = new Set([tokens_1.TokenKind.AND, tokens_1.TokenKind.OR]);
const binopMap = {
    [tokens_1.TokenKind.PLUS]: semantic_1.BinaryOp.PLUS,
    [tokens_1.TokenKind.MINUS]: semantic_1.BinaryOp.MINUS,
    [tokens_1.TokenKind.DIV]: semantic_1.BinaryOp.DIV,
    [tokens_1.TokenKind.MULTIPLY]: semantic_1.BinaryOp.MUL,
    [tokens_1.TokenKind.MOD]: semantic_1.BinaryOp.MOD,
    [tokens_1.TokenKind.AND]: semantic_1.BinaryOp.AND,
    [tokens_1.TokenKind.OR]: semantic_1.BinaryOp.OR,
    [tokens_1.TokenKind.BIT_AND]: semantic_1.BinaryOp.BIT_AND,
    [tokens_1.TokenKind.BIT_OR]: semantic_1.BinaryOp.BIT_OR,
    [tokens_1.TokenKind.BIT_XOR]: semantic_1.BinaryOp.BIT_XOR,
    [tokens_1.TokenKind.EQUAL]: semantic_1.BinaryOp.EQUAL,
    [tokens_1.TokenKind.NOT_EQUAL]: semantic_1.BinaryOp.NOT_EQUAL,
    [tokens_1.TokenKind.GREATER_THAN]: semantic_1.BinaryOp.GREATER_THAN,
    [tokens_1.TokenKind.GREATER_THAN_EQUAL]: semantic_1.BinaryOp.GREATER_THAN_EQUAL,
    [tokens_1.TokenKind.LESS_THAN]: semantic_1.BinaryOp.LESS_THAN,
    [tokens_1.TokenKind.LESS_THAN_EQUAL]: semantic_1.BinaryOp.LESS_THAN_EQUAL,
    [tokens_1.TokenKind.SHIFT_LEFT]: semantic_1.BinaryOp.SHIFT_LEFT,
    [tokens_1.TokenKind.SHIFT_RIGHT]: semantic_1.BinaryOp.SHIFT_RIGHT,
};
function analyzeBinaryExpr(ctx, expr) {
    const aResult = analyzeExpr(ctx, expr.a);
    if (aResult.err !== undefined) {
        return err(aResult.err);
    }
    const bResult = analyzeExpr(ctx, expr.b);
    if (bResult.err !== undefined) {
        return err(bResult.err);
    }
    const a = aResult.ok;
    const b = bResult.ok;
    const op = expr.op;
    let resultType;
    if (integerBinOp.has(op.kind) &&
        typeEqual(a.type, semantic_1.Integer) &&
        typeEqual(b.type, semantic_1.Integer)) {
        resultType = semantic_1.Integer;
    }
    else if (realBinOp.has(op.kind) &&
        typeEqual(a.type, semantic_1.Real) &&
        typeEqual(b.type, semantic_1.Real)) {
        resultType = semantic_1.Real;
    }
    else if (compBinOp.has(op.kind) &&
        ((typeEqual(a.type, semantic_1.Real) && typeEqual(b.type, semantic_1.Real)) ||
            (typeEqual(a.type, semantic_1.Integer) && typeEqual(b.type, semantic_1.Integer)) ||
            (typeEqual(a.type, semantic_1.String) && typeEqual(b.type, semantic_1.String)))) {
        resultType = semantic_1.Boolean;
    }
    else if (boolBinOp.has(op.kind) &&
        typeEqual(a.type, semantic_1.Boolean) &&
        typeEqual(b.type, semantic_1.Boolean)) {
        resultType = semantic_1.Boolean;
    }
    else {
        return err(new errors_1.InvalidBinaryOperator(a, op, b));
    }
    return ok({
        kind: semantic_1.ExprKind.BINARY,
        isConstexpr: false,
        constValue: undefined,
        isAssignable: false,
        type: resultType,
        position: a.position,
        a,
        b,
        op: binopMap[op.kind],
    });
}
const integerUnaryOp = new Set([
    tokens_1.TokenKind.PLUS,
    tokens_1.TokenKind.MINUS,
    tokens_1.TokenKind.BIT_NOT,
]);
const realUnaryOp = new Set([tokens_1.TokenKind.PLUS, tokens_1.TokenKind.MINUS]);
const boolUnaryOp = new Set([tokens_1.TokenKind.NOT]);
const unaryOpMap = {
    [tokens_1.TokenKind.PLUS]: semantic_1.UnaryOp.PLUS,
    [tokens_1.TokenKind.MINUS]: semantic_1.UnaryOp.MINUS,
    [tokens_1.TokenKind.NOT]: semantic_1.UnaryOp.NOT,
    [tokens_1.TokenKind.BIT_NOT]: semantic_1.UnaryOp.BIT_NOT,
};
function analyzeUnaryExpr(ctx, expr) {
    const result = analyzeExpr(ctx, expr.value);
    if (result.err !== undefined) {
        return err(result.err);
    }
    const value = result.ok;
    if (!(expr.op.kind in unaryOpMap)) {
        throw new Error(`invalid unary operator ${expr.op.kind}`);
    }
    const op = expr.op;
    let resultType;
    if (integerUnaryOp.has(op.kind) && typeEqual(value.type, semantic_1.Integer)) {
        resultType = semantic_1.Integer;
    }
    else if (realUnaryOp.has(op.kind) && typeEqual(value.type, semantic_1.Real)) {
        resultType = semantic_1.Real;
    }
    else if (boolUnaryOp.has(op.kind) && typeEqual(value.type, semantic_1.Boolean)) {
        resultType = semantic_1.Boolean;
    }
    else {
        return err(new errors_1.InvalidUnaryOperator(value, op));
    }
    return ok({
        kind: semantic_1.ExprKind.UNARY,
        isConstexpr: false,
        constValue: undefined,
        isAssignable: false,
        type: resultType,
        position: expr.op.position,
        value,
        op: unaryOpMap[op.kind],
    });
}
function analyzeCallExpr(ctx, expr) {
    const calleeResult = analyzeExpr(ctx, expr.callee);
    if (calleeResult.err !== undefined) {
        return err(calleeResult.err);
    }
    const callee = calleeResult.ok;
    if (callee.type.kind !== semantic_1.TypeKind.FUNCTION) {
        return err(new errors_1.TypeMismatch(callee, semantic_1.TypeKind.FUNCTION));
    }
    if (callee.type.arguments.length !== expr.arguments.values.length) {
        return err(new errors_1.WrongNumberOfArgument(expr, callee.type.arguments.length));
    }
    const args = [];
    for (let i = 0; i < callee.type.arguments.length; i++) {
        const result = analyzeExpr(ctx, expr.arguments.values[i]);
        if (result.err !== undefined) {
            return err(result.err);
        }
        const arg = result.ok;
        const paramType = callee.type.arguments[i];
        if (!typeEqual(arg.type, paramType)) {
            return err(new errors_1.TypeMismatch(arg, paramType));
        }
        args.push(arg);
    }
    return ok({
        kind: semantic_1.ExprKind.CALL,
        isConstexpr: false,
        constValue: undefined,
        isAssignable: false,
        type: callee.type.return,
        position: callee.position,
        function: callee,
        arguments: args,
    });
}
function analyzeArrayIndexExpr(ctx, expr) {
    const arrayResult = analyzeExpr(ctx, expr.array);
    if (arrayResult.err !== undefined) {
        return err(arrayResult.err);
    }
    const array = arrayResult.ok;
    if (array.type.kind !== semantic_1.TypeKind.ARRAY) {
        return err(new errors_1.TypeMismatch(array, semantic_1.TypeKind.ARRAY));
    }
    if (array.type.dimension.length !== expr.index.values.length) {
        return err(new errors_1.WrongNumberOfIndex(expr, array.type.dimension.length));
    }
    const indices = [];
    for (let i = 0; i < array.type.dimension.length; i++) {
        const result = analyzeExpr(ctx, expr.index.values[i]);
        if (result.err !== undefined) {
            return err(result.err);
        }
        const index = result.ok;
        if (index.type.kind !== semantic_1.TypeKind.INTEGER) {
            throw new errors_1.TypeMismatch(index, semantic_1.TypeKind.INTEGER);
        }
        indices.push(index);
    }
    return ok({
        kind: semantic_1.ExprKind.INDEX,
        isConstexpr: false,
        constValue: undefined,
        isAssignable: true,
        type: array.type.elementType,
        position: array.position,
        array,
        indices,
    });
}
function analyzeCastExpr(ctx, expr) {
    const valueResult = analyzeExpr(ctx, expr.source);
    if (valueResult.err !== undefined) {
        return err(valueResult.err);
    }
    const value = valueResult.ok;
    const targetResult = analyzeType(ctx, expr.target);
    if (targetResult.err !== undefined) {
        return err(targetResult.err);
    }
    const target = targetResult.ok;
    const castable = (value.type.kind === semantic_1.TypeKind.INTEGER && target.kind === semantic_1.TypeKind.REAL) ||
        (value.type.kind === semantic_1.TypeKind.REAL && target.kind === semantic_1.TypeKind.INTEGER);
    if (!castable) {
        return err(new errors_1.TypeMismatch(value, target));
    }
    return ok({
        kind: semantic_1.ExprKind.CAST,
        isConstexpr: false,
        constValue: undefined,
        isAssignable: false,
        position: value.position,
        source: value,
        type: target,
    });
}
function analyzeType(ctx, expr) {
    if (expr.kind === ast_1.TypeExprNodeKind.IDENT) {
        switch (expr.type.kind) {
            case tokens_1.TokenKind.INTEGER:
                return ok(semantic_1.Integer);
            case tokens_1.TokenKind.BOOLEAN:
                return ok(semantic_1.Boolean);
            case tokens_1.TokenKind.BYTE:
                return ok(semantic_1.Byte);
            case tokens_1.TokenKind.REAL:
                return ok(semantic_1.Real);
            case tokens_1.TokenKind.STRING:
                return ok(semantic_1.String);
            default:
                throw new Error(`unrecognized type ${expr.kind}`);
        }
    }
    else if (expr.kind === ast_1.TypeExprNodeKind.ARRAY) {
        return analyzeArrayType(ctx, expr);
    }
    else {
        throw new Error(`unsupported type kind found`);
    }
}
function analyzeFuncType(ctx, func) {
    const args = [];
    for (const param of func.params.params) {
        const typeResult = analyzeType(ctx, param.type);
        if (typeResult.err !== undefined) {
            return err(typeResult.err);
        }
        args.push(typeResult.ok);
    }
    let returnType = semantic_1.Void;
    if (func.returnType !== undefined) {
        const result = analyzeType(ctx, func.returnType);
        if (result.err !== undefined) {
            return err(result.err);
        }
        returnType = result.ok;
    }
    return ok({ kind: semantic_1.TypeKind.FUNCTION, arguments: args, return: returnType });
}
function analyzeArrayType(ctx, array) {
    const dimension = [];
    for (const dim of array.dimension.values) {
        const result = analyzeExpr(ctx, dim);
        if (result.err !== undefined) {
            return err(result.err);
        }
        dimension.push(result.ok);
    }
    const dimensionNum = [];
    for (const dim of dimension) {
        if (!dim.isConstexpr) {
            return err(new errors_1.NotAConstant(dim));
        }
        if (!typeEqual(dim.type, semantic_1.Integer)) {
            return err(new errors_1.TypeMismatch(dim, semantic_1.Integer));
        }
        dimensionNum.push(dim.constValue);
    }
    const result = analyzeType(ctx, array.elementType);
    if (result.err !== undefined) {
        return err(result.err);
    }
    const elementType = result.ok;
    return ok({
        kind: semantic_1.TypeKind.ARRAY,
        dimension: dimensionNum,
        elementType,
    });
}
function typeEqual(a, b) {
    if (a.kind !== b.kind)
        return false;
    if (a.kind === semantic_1.TypeKind.ARRAY) {
        const bType = b;
        if (a.dimension.length !== bType.dimension.length) {
            return false;
        }
        for (let i = 0; i < a.dimension.length; i++)
            if (a.dimension[i] !== bType.dimension[i]) {
                return false;
            }
        return typeEqual(a.elementType, bType.elementType);
    }
    else if (a.kind === semantic_1.TypeKind.FUNCTION) {
        const bType = b;
        return (typeEqual(a.return, bType.return) &&
            a.arguments.every((t, i) => typeEqual(t, bType.arguments[i])));
    }
    else {
        return a.kind === b.kind;
    }
}
function assertTokenKind(token, kind, msg = '') {
    if (msg.length === 0) {
        msg = `expected token '${token.value}' to be ${kind}, but it is a ${token.kind}`;
    }
    assert(token.kind === kind);
}
function assert(v, msg = 'assertion failed') {
    if (!v) {
        throw new Error(msg);
    }
}
