"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValueKind = void 0;
var ValueKind;
(function (ValueKind) {
    ValueKind["BOOLEAN"] = "BOOLEAN";
    ValueKind["INTEGER"] = "INTEGER";
    ValueKind["REAL"] = "REAL";
    ValueKind["STRING"] = "STRING";
    ValueKind["ARRAY"] = "ARRAY";
    ValueKind["FUNC"] = "FUNC";
    ValueKind["VOID"] = "VOID";
})(ValueKind = exports.ValueKind || (exports.ValueKind = {}));
