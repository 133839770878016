"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.scan = void 0;
const config_1 = require("./config");
const errors_1 = require("./errors");
const tokens_1 = require("./tokens");
function scan(sourceCode) {
    const codes = new SourceCode(sourceCode);
    const tokens = [];
    const errors = [];
    while (!codes.empty()) {
        if (skipWhitespaces(codes))
            continue;
        if (updateResult(scanWord(codes), tokens, errors))
            continue;
        if (updateResult(scanString(codes), tokens, errors))
            continue;
        if (updateResult(scanNumber(codes), tokens, errors))
            continue;
        if (updateResult(scanComment(codes), tokens, errors))
            continue;
        if (updateResult(scanSymbol(codes), tokens, errors))
            continue;
        if (errors.length < config_1.MAX_ERRORS) {
            errors.push(new errors_1.UnexpectedCharacter(codes.char(), codes.pos()));
            if (errors.length === config_1.MAX_ERRORS) {
                errors.push(new errors_1.TooManyErrors());
                break;
            }
        }
        codes.advance(1);
    }
    if (errors.length > 0) {
        throw new errors_1.MultiCompileError(errors);
    }
    return tokens;
}
exports.scan = scan;
class SourceCode {
    constructor(source) {
        this.characters = [];
        this.index = 0;
        const characters = [];
        let line = 1;
        let col = 0;
        const codes = Array.from(source);
        for (const c of codes) {
            col++;
            characters.push({ c, pos: new tokens_1.Position(line, col) });
            if (c === '\n') {
                line++;
                col = 0;
            }
        }
        this.characters = characters;
        this.index = 0;
    }
    char(num = 1) {
        var _a, _b;
        if (num === 1)
            return (_b = (_a = this.characters[this.index]) === null || _a === void 0 ? void 0 : _a.c) !== null && _b !== void 0 ? _b : '';
        return this.characters
            .slice(this.index, this.index + num)
            .map((v) => v.c)
            .join('');
    }
    pos() {
        var _a, _b;
        if (this.characters.length === 0) {
            return new tokens_1.Position(0, 0);
        }
        return ((_b = (_a = this.characters[this.index]) === null || _a === void 0 ? void 0 : _a.pos) !== null && _b !== void 0 ? _b : this.characters[this.characters.length - 1].pos);
    }
    advance(n) {
        this.index += n;
    }
    empty() {
        return this.index >= this.characters.length;
    }
    length() {
        return this.characters.length;
    }
}
function updateResult(scanResult, tokens, errors) {
    const { consumed, token, errors: scanErrors } = scanResult;
    if (token !== undefined) {
        tokens.push(token);
    }
    if (errors.length + scanErrors.length > config_1.MAX_ERRORS) {
        errors.push(...scanErrors.slice(0, config_1.MAX_ERRORS - errors.length));
        errors.push(new errors_1.TooManyErrors());
    }
    else {
        errors.push(...scanErrors);
    }
    return consumed;
}
const whitespaces = new Set([' ', '\t', '\r', '\n']);
function skipWhitespaces(sourceCode) {
    return consumeChars(sourceCode, whitespaces).length > 0;
}
const SCANNED_NOTHING = { consumed: false, errors: [] };
const wordPrefix = new Set('_abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
const wordChars = new Set('_abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789');
const wordToToken = {
    var: tokens_1.TokenKind.VAR,
    type: tokens_1.TokenKind.TYPE,
    struct: tokens_1.TokenKind.STRUCT,
    as: tokens_1.TokenKind.AS,
    function: tokens_1.TokenKind.FUNCTION,
    begin: tokens_1.TokenKind.BEGIN,
    end: tokens_1.TokenKind.END,
    array: tokens_1.TokenKind.ARRAY,
    of: tokens_1.TokenKind.OF,
    integer: tokens_1.TokenKind.INTEGER,
    boolean: tokens_1.TokenKind.BOOLEAN,
    byte: tokens_1.TokenKind.BYTE,
    real: tokens_1.TokenKind.REAL,
    string: tokens_1.TokenKind.STRING,
    and: tokens_1.TokenKind.AND,
    not: tokens_1.TokenKind.NOT,
    or: tokens_1.TokenKind.OR,
    if: tokens_1.TokenKind.IF,
    then: tokens_1.TokenKind.THEN,
    else: tokens_1.TokenKind.ELSE,
    while: tokens_1.TokenKind.WHILE,
    do: tokens_1.TokenKind.DO,
    continue: tokens_1.TokenKind.CONTINUE,
    break: tokens_1.TokenKind.BREAK,
    return: tokens_1.TokenKind.RETURN,
    true: tokens_1.TokenKind.TRUE,
    false: tokens_1.TokenKind.FALSE,
};
function scanWord(sourceCode) {
    var _a;
    if (sourceCode.empty()) {
        return SCANNED_NOTHING;
    }
    const c = sourceCode.char();
    if (!wordPrefix.has(c)) {
        return SCANNED_NOTHING;
    }
    const position = sourceCode.pos();
    const word = consumeChars(sourceCode, wordChars);
    const kind = (_a = wordToToken[word]) !== null && _a !== void 0 ? _a : tokens_1.TokenKind.IDENTIFIER;
    const token = new tokens_1.Token(kind, word, position);
    return {
        consumed: true,
        token,
        errors: [],
    };
}
function consumeChars(sourceCode, chars) {
    let value = '';
    for (; !sourceCode.empty(); sourceCode.advance(1)) {
        const c = sourceCode.char();
        if (!chars.has(c)) {
            break;
        }
        value += c;
    }
    return value;
}
const backslashes = {
    n: '\n',
    r: '\r',
    t: '\t',
    '\\': '\\',
    0: '\0',
    '"': '"',
    "'": "'",
    '`': '`',
};
function scanString(sourceCode) {
    if (sourceCode.empty()) {
        return SCANNED_NOTHING;
    }
    if (sourceCode.char() !== '"') {
        return SCANNED_NOTHING;
    }
    const position = sourceCode.pos();
    const openingQuote = sourceCode.char();
    sourceCode.advance(1);
    let afterBackslash = false;
    let closed = false;
    let value = openingQuote;
    const errors = [];
    for (; !closed && !sourceCode.empty(); sourceCode.advance(1)) {
        const c = sourceCode.char();
        const pos = sourceCode.pos();
        if (c === '\n') {
            errors.push(new errors_1.MissingClosingQuote(pos));
            break;
        }
        if (afterBackslash) {
            if (c in backslashes) {
                value += backslashes[c];
            }
            else {
                errors.push(new errors_1.UnexpectedCharacter(c, pos));
            }
            afterBackslash = false;
        }
        else if (c === '\\') {
            afterBackslash = true;
        }
        else if (c === openingQuote) {
            value += c;
            closed = true;
        }
        else {
            value += c;
        }
    }
    if (!closed) {
        errors.push(new errors_1.MissingClosingQuote(sourceCode.pos()));
    }
    return {
        consumed: true,
        token: new tokens_1.Token(tokens_1.TokenKind.STRING_LITERAL, value, position),
        errors,
    };
}
const digit = new Set('0123456789');
function scanNumber(sourceCode) {
    // TODO: improve number literal. Support:
    // - hexadecimal, binary, octa
    // - floating point
    // - exponent expression
    if (sourceCode.empty()) {
        return SCANNED_NOTHING;
    }
    if (!digit.has(sourceCode.char())) {
        return SCANNED_NOTHING;
    }
    const pos = sourceCode.pos();
    const value = consumeChars(sourceCode, digit);
    if (sourceCode.char() === '.') {
        sourceCode.advance(1);
        const decimals = consumeChars(sourceCode, digit);
        const realValue = value + '.' + decimals;
        return {
            consumed: true,
            token: new tokens_1.Token(tokens_1.TokenKind.REAL_LITERAL, realValue, pos),
            errors: [],
        };
    }
    return {
        consumed: true,
        token: new tokens_1.Token(tokens_1.TokenKind.INTEGER_LITERAL, value, pos),
        errors: [],
    };
}
const symbolMap = [
    ['!=', tokens_1.TokenKind.NOT_EQUAL],
    ['!', tokens_1.TokenKind.NOT],
    ['=', tokens_1.TokenKind.EQUAL],
    ['*', tokens_1.TokenKind.MULTIPLY],
    ['+', tokens_1.TokenKind.PLUS],
    ['->', tokens_1.TokenKind.ARROW],
    ['-', tokens_1.TokenKind.MINUS],
    ['/', tokens_1.TokenKind.DIV],
    [':=', tokens_1.TokenKind.ASSIGN],
    [':', tokens_1.TokenKind.COLON],
    [';', tokens_1.TokenKind.SEMICOLON],
    ['<=', tokens_1.TokenKind.LESS_THAN_EQUAL],
    ['<', tokens_1.TokenKind.LESS_THAN],
    ['>=', tokens_1.TokenKind.GREATER_THAN_EQUAL],
    ['>', tokens_1.TokenKind.GREATER_THAN],
    ['[', tokens_1.TokenKind.OPEN_SQUARE],
    [']', tokens_1.TokenKind.CLOSE_SQUARE],
    ['(', tokens_1.TokenKind.OPEN_BRAC],
    [')', tokens_1.TokenKind.CLOSE_BRAC],
    [',', tokens_1.TokenKind.COMMA],
    ['%', tokens_1.TokenKind.MOD],
];
function scanSymbol(sourceCode) {
    if (sourceCode.empty()) {
        return SCANNED_NOTHING;
    }
    const pos = sourceCode.pos();
    let sym = '';
    let value = '';
    let op;
    while (!sourceCode.empty()) {
        sym += sourceCode.char();
        let match = false;
        for (const [key, value] of symbolMap) {
            if (key.startsWith(sym)) {
                match = true;
                op = value;
            }
        }
        if (!match)
            break;
        value += sourceCode.char();
        sourceCode.advance(1);
    }
    if (op === undefined)
        return SCANNED_NOTHING;
    return { consumed: true, token: new tokens_1.Token(op, value, pos), errors: [] };
}
function scanComment(sourceCode) {
    if (sourceCode.char(2) !== '//') {
        return SCANNED_NOTHING;
    }
    const pos = sourceCode.pos();
    let value = '';
    for (; !sourceCode.empty(); sourceCode.advance(1)) {
        if (sourceCode.char() === '\n')
            break;
        value += sourceCode.char();
    }
    return {
        consumed: true,
        token: new tokens_1.Token(tokens_1.TokenKind.COMMENT, value, pos),
        errors: [],
    };
}
