"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Machine = void 0;
const lang_1 = require("@pagora/lang");
const display_1 = require("./display");
const status_1 = require("./status");
const symbols_1 = require("./symbols");
const value_1 = require("./value");
class Machine {
    constructor() {
        this.displayer = new display_1.NopDisplayer();
        this.statusWriter = new status_1.NopStatusWriter();
        this.symbols = new symbols_1.SymbolTable();
    }
    start(program) {
        this.startTime = Date.now();
        this.symbols.reset();
        this.symbols.addScope();
        this.onKeyDownHandler = undefined;
        this.onMouseMoveHandler = undefined;
        this.onMouseClickHandler = undefined;
        this.onUpdateHandler = undefined;
        for (const global of program.globals) {
            const value = global.value != null
                ? evalExpr(this.symbols, global.value)
                : zeroValue(global.type);
            this.symbols.setSymbol(global.name, value);
        }
        for (const func of program.functions) {
            const value = {
                kind: value_1.ValueKind.FUNC,
                value: (args) => {
                    return this.executeFunc(func, args);
                },
            };
            this.symbols.setSymbol(func.name, value);
        }
        this.statusWriter.clear();
        this.displayer.clearAndReset();
        this.displayer.onKeyDown((key) => {
            this.keydownHandler(key);
        });
        this.displayer.onMouseMove((x, y) => {
            this.mouseMoveHandler(x, y);
        });
        this.displayer.onMouseClick((x, y) => {
            this.mouseClickHandler(x, y);
        });
        executeStatement(this.symbols, program.main);
        const fps = 12;
        if (this.timer === undefined) {
            this.timer = setInterval(() => {
                if (this.onUpdateHandler != null) {
                    this.onUpdateHandler.value([]);
                }
            }, 1000 / fps);
        }
        this.displayer.start();
    }
    stop() {
        this.displayer.stop();
        if (this.timer !== undefined) {
            clearInterval(this.timer);
            this.timer = undefined;
        }
    }
    attachDisplayer(displayer) {
        this.displayer = displayer;
        this.displayer.onKeyDown((key) => {
            this.keydownHandler(key);
        });
    }
    attachStatusWriter(statusWriter) {
        this.statusWriter = statusWriter;
    }
    keydownHandler(key) {
        if (this.onKeyDownHandler === undefined)
            return;
        this.onKeyDownHandler.value([{ kind: value_1.ValueKind.STRING, value: key }]);
    }
    mouseMoveHandler(x, y) {
        if (this.onMouseMoveHandler === undefined)
            return;
        this.onMouseMoveHandler.value([
            { kind: value_1.ValueKind.INTEGER, value: BigInt(x) },
            { kind: value_1.ValueKind.INTEGER, value: BigInt(y) },
        ]);
    }
    mouseClickHandler(x, y) {
        if (this.onMouseClickHandler === undefined)
            return;
        this.onMouseClickHandler.value([
            { kind: value_1.ValueKind.INTEGER, value: BigInt(x) },
            { kind: value_1.ValueKind.INTEGER, value: BigInt(y) },
        ]);
    }
    executeFunc(funcDecl, args) {
        if (funcDecl.body === undefined) {
            return this.executeNativeFunc(funcDecl.name, args);
        }
        let returnVal = zeroValue(funcDecl.type.return);
        this.symbols.addScope();
        for (let i = 0; i < funcDecl.arguments.length; i++) {
            this.symbols.setSymbol(funcDecl.arguments[i], args[i]);
        }
        const control = executeBlockStmt(this.symbols, funcDecl.body);
        this.symbols.popScope();
        if (control.kind === ControlKind.RETURN) {
            returnVal = control.value;
        }
        return returnVal;
    }
    executeNativeFunc(name, args) {
        switch (name) {
            case 'output':
                if (args.length !== 1) {
                    throw new Error(`invalid arguments. Expected 1 argument, but found ${args.length} arguments`);
                }
                if (args[0].kind !== value_1.ValueKind.STRING) {
                    throw new Error(`invalid arguments. First argument expected to be string but found ${args[0].kind}`);
                }
                this.statusWriter.append(args[0].value);
                return { kind: value_1.ValueKind.VOID, value: undefined };
            case 'draw_rect': {
                if (args.length !== 6) {
                    throw new Error(`invalid arguments. Expected 6 argument, but found ${args.length} arguments`);
                }
                const [xVal, yVal, wVal, hVal, strokeVal, bgVal] = args;
                if (xVal.kind !== value_1.ValueKind.INTEGER) {
                    throw new Error(`invalid arguments. First argument expected to be integer but found ${args[0].kind}`);
                }
                if (yVal.kind !== value_1.ValueKind.INTEGER) {
                    throw new Error(`invalid arguments. Second argument expected to be integer but found ${args[1].kind}`);
                }
                if (wVal.kind !== value_1.ValueKind.INTEGER) {
                    throw new Error(`invalid arguments. Third argument expected to be integer but found ${args[2].kind}`);
                }
                if (hVal.kind !== value_1.ValueKind.INTEGER) {
                    throw new Error(`invalid arguments. Forth argument expected to be integer but found ${args[3].kind}`);
                }
                if (strokeVal.kind !== value_1.ValueKind.STRING) {
                    throw new Error(`invalid arguments. Fifth argument expected to be integer but found ${args[4].kind}`);
                }
                if (bgVal.kind !== value_1.ValueKind.STRING) {
                    throw new Error(`invalid arguments. Sixth argument expected to be integer but found ${args[5].kind}`);
                }
                const x = Number(xVal.value);
                const y = Number(yVal.value);
                const w = Number(wVal.value);
                const h = Number(hVal.value);
                const strokeColor = strokeVal.value;
                const bgColor = bgVal.value;
                this.displayer.drawRect(x, y, w, h, strokeColor, bgColor);
                return { kind: value_1.ValueKind.VOID, value: undefined };
            }
            case 'get_width':
                return {
                    kind: value_1.ValueKind.INTEGER,
                    value: BigInt(this.displayer.getWidth()),
                };
            case 'get_height':
                return {
                    kind: value_1.ValueKind.INTEGER,
                    value: BigInt(this.displayer.getHeigh()),
                };
            case 'register_on_update':
                if (args.length !== 1) {
                    throw new Error(`invalid arguments. Expected 1 argument, but found ${args.length} arguments`);
                }
                if (args[0].kind !== value_1.ValueKind.FUNC) {
                    throw new Error(`invalid arguments. First argument expected to be a function but found ${args[0].kind}`);
                }
                this.onUpdateHandler = args[0];
                return { kind: value_1.ValueKind.VOID, value: undefined };
            case 'register_on_keydown':
                if (args.length !== 1) {
                    throw new Error(`invalid arguments. Expected 1 argument, but found ${args.length} arguments`);
                }
                if (args[0].kind !== value_1.ValueKind.FUNC) {
                    throw new Error(`invalid arguments. First argument expected to be a function but found ${args[0].kind}`);
                }
                if (args[0].kind !== value_1.ValueKind.FUNC)
                    throw new Error('invalid state. registering non function');
                this.onKeyDownHandler = args[0];
                return { kind: value_1.ValueKind.VOID, value: undefined };
            case 'register_on_mouse_move':
                if (args.length !== 1) {
                    throw new Error(`invalid arguments. Expected 1 argument, but found ${args.length} arguments`);
                }
                if (args[0].kind !== value_1.ValueKind.FUNC) {
                    throw new Error(`invalid arguments. First argument expected to be a function but found ${args[0].kind}`);
                }
                if (args[0].kind !== value_1.ValueKind.FUNC)
                    throw new Error('invalid state. registering non function');
                this.onMouseMoveHandler = args[0];
                return { kind: value_1.ValueKind.VOID, value: undefined };
            case 'register_on_mouse_click':
                if (args.length !== 1) {
                    throw new Error(`invalid arguments. Expected 1 argument, but found ${args.length} arguments`);
                }
                if (args[0].kind !== value_1.ValueKind.FUNC) {
                    throw new Error(`invalid arguments. First argument expected to be a function but found ${args[0].kind}`);
                }
                if (args[0].kind !== value_1.ValueKind.FUNC)
                    throw new Error('invalid state. registering non function');
                this.onMouseClickHandler = args[0];
                return { kind: value_1.ValueKind.VOID, value: undefined };
            case 'unix_time_millis':
                return {
                    kind: value_1.ValueKind.INTEGER,
                    value: BigInt(Date.now()),
                };
            case 'system_time_millis':
                return {
                    kind: value_1.ValueKind.INTEGER,
                    value: BigInt(Date.now() - this.startTime),
                };
            default:
                throw new Error(`native function '${name}' is not implemented yet`);
        }
    }
}
exports.Machine = Machine;
var ControlKind;
(function (ControlKind) {
    ControlKind["NORMAL"] = "NORMAL";
    ControlKind["CONTINUE"] = "CONTINUE";
    ControlKind["BREAK"] = "BREAK";
    ControlKind["RETURN"] = "RETURN";
})(ControlKind || (ControlKind = {}));
function executeStatement(symbols, stmt) {
    switch (stmt.kind) {
        case lang_1.StatementKind.BLOCK:
            return executeBlockStmt(symbols, stmt);
        case lang_1.StatementKind.VAR:
            return executeVarStmt(symbols, stmt);
        case lang_1.StatementKind.IF: {
            return executeIfStmt(symbols, stmt);
        }
        case lang_1.StatementKind.WHILE:
            return executeWhileStmt(symbols, stmt);
        case lang_1.StatementKind.ASSIGN: {
            const value = evalExpr(symbols, stmt.value);
            const target = evalExpr(symbols, stmt.target);
            target.value = value.value;
            return { kind: ControlKind.NORMAL };
        }
        case lang_1.StatementKind.EXPR:
            evalExpr(symbols, stmt.value);
            return { kind: ControlKind.NORMAL };
        case lang_1.StatementKind.RETURN: {
            let returnValue;
            if (stmt.value !== undefined) {
                const value = evalExpr(symbols, stmt.value);
                returnValue = value;
            }
            else {
                returnValue = { kind: value_1.ValueKind.VOID, value: undefined };
            }
            return { kind: ControlKind.RETURN, value: returnValue };
        }
        case lang_1.StatementKind.BREAK:
            return { kind: ControlKind.BREAK };
        case lang_1.StatementKind.CONTINUE:
            return { kind: ControlKind.CONTINUE };
    }
}
function executeBlockStmt(symbols, stmt) {
    symbols.addScope();
    for (const s of stmt.body) {
        const control = executeStatement(symbols, s);
        switch (control.kind) {
            case ControlKind.BREAK:
            case ControlKind.CONTINUE:
            case ControlKind.RETURN:
                symbols.popScope();
                return control;
            case ControlKind.NORMAL:
                break;
            default:
                throw new Error('unreachable');
        }
    }
    symbols.popScope();
    return { kind: ControlKind.NORMAL };
}
function executeVarStmt(symbols, stmt) {
    const varValue = zeroValue(stmt.variable.type);
    if (stmt.variable.value !== undefined) {
        const value = evalExpr(symbols, stmt.variable.value);
        varValue.value = value.value;
    }
    symbols.setSymbol(stmt.variable.name, varValue);
    return { kind: ControlKind.NORMAL };
}
function executeIfStmt(symbols, stmt) {
    const cond = evalExpr(symbols, stmt.condition);
    if (cond.value) {
        return executeStatement(symbols, stmt.body);
    }
    else if (stmt.else !== undefined) {
        return executeStatement(symbols, stmt.else);
    }
    return { kind: ControlKind.NORMAL };
}
function executeWhileStmt(symbols, stmt) {
    while (true) {
        const cond = evalExpr(symbols, stmt.condition);
        if (!cond.value)
            break;
        const control = executeStatement(symbols, stmt.body);
        if (control.kind === ControlKind.BREAK) {
            return { kind: ControlKind.NORMAL };
        }
        else if (control.kind === ControlKind.CONTINUE) {
            continue;
        }
        else if (control.kind === ControlKind.RETURN) {
            return control;
        }
        else if (control.kind !== ControlKind.NORMAL) {
            throw new Error(`unreachable state`);
        }
    }
    return { kind: ControlKind.NORMAL };
}
function evalExpr(symbols, expr) {
    switch (expr.kind) {
        case lang_1.ExprKind.BINARY:
            return evalBinary(symbols, expr);
        case lang_1.ExprKind.UNARY:
            return evalUnary(symbols, expr);
        case lang_1.ExprKind.INDEX: {
            const arr = evalExpr(symbols, expr.array);
            const indices = expr.indices.map((v) => evalExpr(symbols, v));
            if (expr.array.type.kind !== lang_1.TypeKind.ARRAY) {
                throw new Error('invalid state. indexing non array');
            }
            let val = arr;
            for (const i of indices) {
                if (i.kind !== value_1.ValueKind.INTEGER) {
                    throw new Error('invalid state. indexing witout integer');
                }
                val = val.value[Number(i.value)];
            }
            return val;
        }
        case lang_1.ExprKind.CAST:
            if (expr.source.type.kind === lang_1.TypeKind.REAL &&
                expr.type.kind === lang_1.TypeKind.INTEGER) {
                const source = evalExpr(symbols, expr.source);
                if (source.kind !== value_1.ValueKind.REAL) {
                    throw new Error(`invalid state, source should be resolved to a real number`);
                }
                return {
                    kind: value_1.ValueKind.INTEGER,
                    value: BigInt.asIntN(64, BigInt(source.value)),
                };
            }
            throw new Error('Cast operation is not implemented yet');
        case lang_1.ExprKind.CALL: {
            const args = expr.arguments.map((v) => evalExpr(symbols, v));
            const func = evalExpr(symbols, expr.function);
            if (func.kind !== value_1.ValueKind.FUNC) {
                throw new Error('invalid state. calling non functtion');
            }
            return func.value(args);
        }
        case lang_1.ExprKind.INTEGER_LIT:
            return { kind: value_1.ValueKind.INTEGER, value: expr.value };
        case lang_1.ExprKind.BOOLEAN_LIT:
            return { kind: value_1.ValueKind.BOOLEAN, value: expr.value };
        case lang_1.ExprKind.STRING_LIT:
            return { kind: value_1.ValueKind.STRING, value: expr.value };
        case lang_1.ExprKind.ARRAY_LIT:
            return {
                kind: value_1.ValueKind.ARRAY,
                value: expr.values.map((v) => {
                    const val = evalExpr(symbols, v);
                    return { kind: val.kind, value: val.value };
                }),
            };
        case lang_1.ExprKind.IDENT:
            return symbols.getSymbol(expr.ident);
        default:
            throw new Error(`eval ${expr.kind} is not implemented yet`);
    }
}
function evalBinary(symbols, expr) {
    switch (expr.op) {
        case lang_1.BinaryOp.PLUS: {
            const a = evalExpr(symbols, expr.a);
            const b = evalExpr(symbols, expr.b);
            if (a.kind === value_1.ValueKind.REAL && b.kind === value_1.ValueKind.REAL) {
                return { kind: value_1.ValueKind.REAL, value: a.value + b.value };
            }
            else if (a.kind === value_1.ValueKind.INTEGER && b.kind === value_1.ValueKind.INTEGER) {
                return {
                    kind: value_1.ValueKind.INTEGER,
                    value: BigInt.asIntN(64, a.value + b.value),
                };
            }
            else {
                throw new Error(`Cannot perform binary operation ${expr.op} on ${a.kind}`);
            }
        }
        case lang_1.BinaryOp.MINUS: {
            const a = evalExpr(symbols, expr.a);
            const b = evalExpr(symbols, expr.b);
            if (a.kind === value_1.ValueKind.REAL && b.kind === value_1.ValueKind.REAL) {
                return { kind: value_1.ValueKind.REAL, value: a.value - b.value };
            }
            else if (a.kind === value_1.ValueKind.INTEGER && b.kind === value_1.ValueKind.INTEGER) {
                return {
                    kind: value_1.ValueKind.INTEGER,
                    value: BigInt.asIntN(64, a.value - b.value),
                };
            }
            else {
                throw new Error(`Cannot perform binary operation ${expr.op} on ${a.kind}`);
            }
        }
        case lang_1.BinaryOp.DIV: {
            const a = evalExpr(symbols, expr.a);
            const b = evalExpr(symbols, expr.b);
            if (a.kind === value_1.ValueKind.REAL && b.kind === value_1.ValueKind.REAL) {
                return { kind: value_1.ValueKind.REAL, value: a.value - b.value };
            }
            else if (a.kind === value_1.ValueKind.INTEGER && b.kind === value_1.ValueKind.INTEGER) {
                return {
                    kind: value_1.ValueKind.INTEGER,
                    value: BigInt.asIntN(64, a.value / b.value),
                };
            }
            else {
                throw new Error(`Cannot perform binary operation ${expr.op} on ${a.kind}`);
            }
        }
        case lang_1.BinaryOp.MUL: {
            const a = evalExpr(symbols, expr.a);
            const b = evalExpr(symbols, expr.b);
            if (a.kind === value_1.ValueKind.REAL && b.kind === value_1.ValueKind.REAL) {
                return { kind: value_1.ValueKind.REAL, value: a.value * b.value };
            }
            else if (a.kind === value_1.ValueKind.INTEGER && b.kind === value_1.ValueKind.INTEGER) {
                return {
                    kind: value_1.ValueKind.INTEGER,
                    value: BigInt.asIntN(64, a.value * b.value),
                };
            }
            else {
                throw new Error(`Cannot perform binary operation ${expr.op} on ${a.kind}`);
            }
        }
        case lang_1.BinaryOp.MOD: {
            const a = evalExpr(symbols, expr.a);
            const b = evalExpr(symbols, expr.b);
            if (a.kind === value_1.ValueKind.INTEGER && b.kind === value_1.ValueKind.INTEGER) {
                return {
                    kind: value_1.ValueKind.INTEGER,
                    value: BigInt.asIntN(64, a.value % b.value),
                };
            }
            else {
                throw new Error(`Cannot perform binary operation ${expr.op} on ${a.kind}`);
            }
        }
        case lang_1.BinaryOp.AND: {
            const a = evalExpr(symbols, expr.a);
            if (a.kind !== value_1.ValueKind.BOOLEAN) {
                throw new Error(`Cannot perform binary operation ${expr.op} on ${a.kind}`);
            }
            if (!a.value) {
                return a;
            }
            const b = evalExpr(symbols, expr.b);
            if (b.kind !== value_1.ValueKind.BOOLEAN) {
                throw new Error(`Cannot perform binary operation ${expr.op} on ${a.kind}`);
            }
            return { kind: value_1.ValueKind.BOOLEAN, value: a.value && b.value };
        }
        case lang_1.BinaryOp.OR: {
            const a = evalExpr(symbols, expr.a);
            if (a.kind !== value_1.ValueKind.BOOLEAN) {
                throw new Error(`Cannot perform binary operation ${expr.op} on ${a.kind}`);
            }
            if (a.value) {
                return a;
            }
            const b = evalExpr(symbols, expr.b);
            if (b.kind !== value_1.ValueKind.BOOLEAN) {
                throw new Error(`Cannot perform binary operation ${expr.op} on ${a.kind}`);
            }
            return { kind: value_1.ValueKind.BOOLEAN, value: a.value || b.value };
        }
        case lang_1.BinaryOp.BIT_AND: {
            const a = evalExpr(symbols, expr.a);
            const b = evalExpr(symbols, expr.b);
            if (a.kind === value_1.ValueKind.INTEGER && b.kind === value_1.ValueKind.INTEGER) {
                return {
                    kind: value_1.ValueKind.INTEGER,
                    value: BigInt.asIntN(64, BigInt.asIntN(64, a.value & b.value)),
                };
            }
            else {
                throw new Error(`Cannot perform binary operation ${expr.op} on ${a.kind}`);
            }
        }
        case lang_1.BinaryOp.BIT_OR: {
            const a = evalExpr(symbols, expr.a);
            const b = evalExpr(symbols, expr.b);
            if (a.kind === value_1.ValueKind.INTEGER && b.kind === value_1.ValueKind.INTEGER) {
                return {
                    kind: value_1.ValueKind.INTEGER,
                    value: BigInt.asIntN(64, BigInt.asIntN(64, a.value | b.value)),
                };
            }
            else {
                throw new Error(`Cannot perform binary operation ${expr.op} on ${a.kind}`);
            }
        }
        case lang_1.BinaryOp.BIT_XOR: {
            const a = evalExpr(symbols, expr.a);
            const b = evalExpr(symbols, expr.b);
            if (a.kind === value_1.ValueKind.INTEGER && b.kind === value_1.ValueKind.INTEGER) {
                return {
                    kind: value_1.ValueKind.INTEGER,
                    value: BigInt.asIntN(64, BigInt.asIntN(64, a.value ^ b.value)),
                };
            }
            else {
                throw new Error(`Cannot perform binary operation ${expr.op} on ${a.kind}`);
            }
        }
        case lang_1.BinaryOp.EQUAL: {
            const a = evalExpr(symbols, expr.a);
            const b = evalExpr(symbols, expr.b);
            if (a.kind === value_1.ValueKind.BOOLEAN && b.kind === value_1.ValueKind.BOOLEAN) {
                return { kind: value_1.ValueKind.BOOLEAN, value: a.value === b.value };
            }
            else if (a.kind === value_1.ValueKind.INTEGER && b.kind === value_1.ValueKind.INTEGER) {
                return { kind: value_1.ValueKind.BOOLEAN, value: a.value === b.value };
            }
            else if (a.kind === value_1.ValueKind.REAL && b.kind === value_1.ValueKind.REAL) {
                return { kind: value_1.ValueKind.BOOLEAN, value: a.value === b.value };
            }
            else if (a.kind === value_1.ValueKind.STRING && b.kind === value_1.ValueKind.STRING) {
                return { kind: value_1.ValueKind.BOOLEAN, value: a.value === b.value };
            }
            else {
                throw new Error(`Cannot perform binary operation ${expr.op} on ${a.kind}`);
            }
        }
        case lang_1.BinaryOp.NOT_EQUAL: {
            const a = evalExpr(symbols, expr.a);
            const b = evalExpr(symbols, expr.b);
            if (a.kind === value_1.ValueKind.BOOLEAN && b.kind === value_1.ValueKind.BOOLEAN) {
                return { kind: value_1.ValueKind.BOOLEAN, value: a.value !== b.value };
            }
            else if (a.kind === value_1.ValueKind.INTEGER && b.kind === value_1.ValueKind.INTEGER) {
                return { kind: value_1.ValueKind.BOOLEAN, value: a.value !== b.value };
            }
            else if (a.kind === value_1.ValueKind.REAL && b.kind === value_1.ValueKind.REAL) {
                return { kind: value_1.ValueKind.BOOLEAN, value: a.value !== b.value };
            }
            else if (a.kind === value_1.ValueKind.STRING && b.kind === value_1.ValueKind.STRING) {
                return { kind: value_1.ValueKind.BOOLEAN, value: a.value !== b.value };
            }
            else {
                throw new Error(`Cannot perform binary operation ${expr.op} on ${a.kind}`);
            }
        }
        case lang_1.BinaryOp.GREATER_THAN: {
            const a = evalExpr(symbols, expr.a);
            const b = evalExpr(symbols, expr.b);
            if (a.kind === value_1.ValueKind.REAL && b.kind === value_1.ValueKind.REAL) {
                return { kind: value_1.ValueKind.BOOLEAN, value: a.value > b.value };
            }
            else if (a.kind === value_1.ValueKind.INTEGER && b.kind === value_1.ValueKind.INTEGER) {
                return { kind: value_1.ValueKind.BOOLEAN, value: a.value > b.value };
            }
            else {
                throw new Error(`Cannot perform binary operation ${expr.op} on ${a.kind}`);
            }
        }
        case lang_1.BinaryOp.GREATER_THAN_EQUAL: {
            const a = evalExpr(symbols, expr.a);
            const b = evalExpr(symbols, expr.b);
            if (a.kind === value_1.ValueKind.REAL && b.kind === value_1.ValueKind.REAL) {
                return { kind: value_1.ValueKind.BOOLEAN, value: a.value >= b.value };
            }
            else if (a.kind === value_1.ValueKind.INTEGER && b.kind === value_1.ValueKind.INTEGER) {
                return { kind: value_1.ValueKind.BOOLEAN, value: a.value >= b.value };
            }
            else {
                throw new Error(`Cannot perform binary operation ${expr.op} on ${a.kind}`);
            }
        }
        case lang_1.BinaryOp.LESS_THAN: {
            const a = evalExpr(symbols, expr.a);
            const b = evalExpr(symbols, expr.b);
            if (a.kind === value_1.ValueKind.REAL && b.kind === value_1.ValueKind.REAL) {
                return { kind: value_1.ValueKind.BOOLEAN, value: a.value < b.value };
            }
            else if (a.kind === value_1.ValueKind.INTEGER && b.kind === value_1.ValueKind.INTEGER) {
                return { kind: value_1.ValueKind.BOOLEAN, value: a.value < b.value };
            }
            else {
                throw new Error(`Cannot perform binary operation ${expr.op} on ${a.kind}`);
            }
        }
        case lang_1.BinaryOp.LESS_THAN_EQUAL: {
            const a = evalExpr(symbols, expr.a);
            const b = evalExpr(symbols, expr.b);
            if (a.kind === value_1.ValueKind.REAL && b.kind === value_1.ValueKind.REAL) {
                return { kind: value_1.ValueKind.BOOLEAN, value: a.value <= b.value };
            }
            else if (a.kind === value_1.ValueKind.INTEGER && b.kind === value_1.ValueKind.INTEGER) {
                return { kind: value_1.ValueKind.BOOLEAN, value: a.value <= b.value };
            }
            else {
                throw new Error(`Cannot perform binary operation ${expr.op} on ${a.kind}`);
            }
        }
        case lang_1.BinaryOp.SHIFT_LEFT: {
            const a = evalExpr(symbols, expr.a);
            const b = evalExpr(symbols, expr.b);
            if (a.kind === value_1.ValueKind.INTEGER && b.kind === value_1.ValueKind.INTEGER) {
                return {
                    kind: value_1.ValueKind.INTEGER,
                    value: BigInt.asIntN(64, a.value << b.value),
                };
            }
            else {
                throw new Error(`Cannot perform binary operation ${expr.op} on ${a.kind}`);
            }
        }
        case lang_1.BinaryOp.SHIFT_RIGHT: {
            const a = evalExpr(symbols, expr.a);
            const b = evalExpr(symbols, expr.b);
            if (a.kind === value_1.ValueKind.INTEGER && b.kind === value_1.ValueKind.INTEGER) {
                return {
                    kind: value_1.ValueKind.INTEGER,
                    value: BigInt.asIntN(64, a.value >> b.value),
                };
            }
            else {
                throw new Error(`Cannot perform binary operation ${expr.op} on ${a.kind}`);
            }
        }
        default:
            throw new Error(`Cannot perform binary operation. Unrecognized operator`);
    }
}
function evalUnary(symbols, expr) {
    const v = evalExpr(symbols, expr.value);
    switch (expr.op) {
        case lang_1.UnaryOp.PLUS:
            switch (v.kind) {
                case value_1.ValueKind.INTEGER:
                    return { kind: value_1.ValueKind.INTEGER, value: v.value };
                case value_1.ValueKind.REAL:
                    return { kind: value_1.ValueKind.REAL, value: v.value };
                default:
                    throw new Error(`Cannot perform unary PLUS operation on ${v.kind} type`);
            }
        case lang_1.UnaryOp.MINUS: {
            switch (v.kind) {
                case value_1.ValueKind.INTEGER:
                    return { kind: value_1.ValueKind.INTEGER, value: BigInt.asIntN(64, -v.value) };
                case value_1.ValueKind.REAL:
                    return { kind: value_1.ValueKind.REAL, value: -v.value };
                default:
                    throw new Error(`Cannot perform unary MINUS operation on ${v.kind} type`);
            }
        }
        case lang_1.UnaryOp.NOT:
            if (v.kind !== value_1.ValueKind.BOOLEAN) {
                throw new Error(`Cannot perform unary NOT operation on ${v.kind} type`);
            }
            return { kind: value_1.ValueKind.BOOLEAN, value: !v.value };
        case lang_1.UnaryOp.BIT_NOT:
            if (v.kind !== value_1.ValueKind.INTEGER) {
                throw new Error(`Cannot perform unary BIT_NOT operation on ${v.kind} type`);
            }
            return { kind: value_1.ValueKind.INTEGER, value: BigInt.asIntN(64, ~v.value) };
    }
}
function zeroValue(t) {
    switch (t.kind) {
        case lang_1.TypeKind.INTEGER:
            return { kind: value_1.ValueKind.INTEGER, value: BigInt(0) };
        case lang_1.TypeKind.REAL:
            return { kind: value_1.ValueKind.REAL, value: 0 };
        case lang_1.TypeKind.BOOLEAN:
            return { kind: value_1.ValueKind.BOOLEAN, value: false };
        case lang_1.TypeKind.STRING:
            return { kind: value_1.ValueKind.STRING, value: '' };
        case lang_1.TypeKind.ARRAY:
            return zeroArray(t.dimension, t.elementType);
        case lang_1.TypeKind.VOID:
            return { kind: value_1.ValueKind.VOID, value: undefined };
        default:
            throw new Error(`Failed creating zero value of ${t.kind}. ${t.kind} type is not implemented yet.`);
    }
}
function zeroArray(dimension, elementType) {
    const value = [];
    if (dimension.length === 1) {
        for (let i = 0; i < dimension[0]; i++)
            value.push(zeroValue(elementType));
    }
    else {
        for (let i = 0; i < dimension[0]; i++)
            value.push(zeroArray(dimension.slice(1), elementType));
    }
    return { kind: value_1.ValueKind.ARRAY, value };
}
