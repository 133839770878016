"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExprNodeKind = exports.StatementNodeKind = exports.TypeExprNodeKind = void 0;
var TypeExprNodeKind;
(function (TypeExprNodeKind) {
    TypeExprNodeKind["IDENT"] = "IDENT";
    TypeExprNodeKind["ARRAY"] = "ARRAY";
    TypeExprNodeKind["STRUCT"] = "STRUCT";
    TypeExprNodeKind["TUPLE"] = "TUPLE";
})(TypeExprNodeKind = exports.TypeExprNodeKind || (exports.TypeExprNodeKind = {}));
var StatementNodeKind;
(function (StatementNodeKind) {
    StatementNodeKind["VAR"] = "VAR";
    StatementNodeKind["ASSIGN"] = "ASSIGN";
    StatementNodeKind["RETURN"] = "RETURN";
    StatementNodeKind["IF"] = "IF";
    StatementNodeKind["WHILE"] = "WHILE";
    StatementNodeKind["KEYWORD"] = "KEYWORD";
    StatementNodeKind["BLOCK"] = "BLOCK";
    StatementNodeKind["EXPR"] = "EXPR";
})(StatementNodeKind = exports.StatementNodeKind || (exports.StatementNodeKind = {}));
var ExprNodeKind;
(function (ExprNodeKind) {
    ExprNodeKind["IDENT"] = "IDENT";
    ExprNodeKind["INTEGER_LIT"] = "INTEGER_LIT";
    ExprNodeKind["REAL_LIT"] = "REAL_LIT";
    ExprNodeKind["BOOLEAN_LIT"] = "BOOLEAN_LIT";
    ExprNodeKind["ARRAY_LIT"] = "ARRAY_LIT";
    ExprNodeKind["TUPLE_LIT"] = "TUPLE_LIT";
    ExprNodeKind["STRING_LIT"] = "STRING_LIT";
    ExprNodeKind["BINARY"] = "BINARY";
    ExprNodeKind["UNARY"] = "UNARY";
    ExprNodeKind["CALL"] = "CALL";
    ExprNodeKind["ARRAY_INDEX"] = "ARRAY_INDEX";
    ExprNodeKind["TUPLE_INDEX"] = "TUPLE_INDEX";
    ExprNodeKind["STRUCT_INDEX"] = "STRUCT_INDEX";
    ExprNodeKind["CAST"] = "CAST";
    ExprNodeKind["GROUPED"] = "GROUPED";
})(ExprNodeKind = exports.ExprNodeKind || (exports.ExprNodeKind = {}));
