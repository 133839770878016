"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnaryOp = exports.BinaryOp = exports.ExprKind = exports.StatementKind = exports.Void = exports.Byte = exports.String = exports.Boolean = exports.Real = exports.Integer = exports.TypeKind = void 0;
var TypeKind;
(function (TypeKind) {
    TypeKind["VOID"] = "VOID";
    TypeKind["INTEGER"] = "INTEGER";
    TypeKind["REAL"] = "REAL";
    TypeKind["BOOLEAN"] = "BOOLEAN";
    TypeKind["STRING"] = "STRING";
    TypeKind["BYTE"] = "BYTE";
    TypeKind["ARRAY"] = "ARRAY";
    TypeKind["FUNCTION"] = "FUNCTION";
})(TypeKind = exports.TypeKind || (exports.TypeKind = {}));
exports.Integer = { kind: TypeKind.INTEGER };
exports.Real = { kind: TypeKind.REAL };
exports.Boolean = { kind: TypeKind.BOOLEAN };
exports.String = { kind: TypeKind.STRING };
exports.Byte = { kind: TypeKind.BYTE };
exports.Void = { kind: TypeKind.VOID };
var StatementKind;
(function (StatementKind) {
    StatementKind["BLOCK"] = "BLOCK";
    StatementKind["VAR"] = "VAR";
    StatementKind["IF"] = "IF";
    StatementKind["WHILE"] = "WHILE";
    StatementKind["ASSIGN"] = "ASSIGN";
    StatementKind["EXPR"] = "EXPR";
    StatementKind["RETURN"] = "RETURN";
    StatementKind["BREAK"] = "BREAK";
    StatementKind["CONTINUE"] = "CONTINUE";
})(StatementKind = exports.StatementKind || (exports.StatementKind = {}));
var ExprKind;
(function (ExprKind) {
    ExprKind["BINARY"] = "BINARY";
    ExprKind["UNARY"] = "UNARY";
    ExprKind["INDEX"] = "INDEX";
    ExprKind["CAST"] = "CAST";
    ExprKind["CALL"] = "CALL";
    ExprKind["INTEGER_LIT"] = "INTEGER_LIT";
    ExprKind["CHAR_LIT"] = "CHAR_LIT";
    ExprKind["STRING_LIT"] = "STRING_LIT";
    ExprKind["REAL_LIT"] = "REAL_LIT";
    ExprKind["BOOLEAN_LIT"] = "BOOLEAN_LIT";
    ExprKind["ARRAY_LIT"] = "ARRAY_LIT";
    ExprKind["IDENT"] = "IDENT";
})(ExprKind = exports.ExprKind || (exports.ExprKind = {}));
var BinaryOp;
(function (BinaryOp) {
    BinaryOp["PLUS"] = "PLUS";
    BinaryOp["MINUS"] = "MINUS";
    BinaryOp["DIV"] = "DIV";
    BinaryOp["MUL"] = "MUL";
    BinaryOp["MOD"] = "MOD";
    BinaryOp["AND"] = "AND";
    BinaryOp["OR"] = "OR";
    BinaryOp["BIT_AND"] = "BIT_AND";
    BinaryOp["BIT_OR"] = "BIT_OR";
    BinaryOp["BIT_XOR"] = "BIT_XOR";
    BinaryOp["EQUAL"] = "EQUAL";
    BinaryOp["NOT_EQUAL"] = "NOT_EQUAL";
    BinaryOp["GREATER_THAN"] = "GREATER_THAN";
    BinaryOp["GREATER_THAN_EQUAL"] = "GREATER_THAN_EQUAL";
    BinaryOp["LESS_THAN"] = "LESS_THAN";
    BinaryOp["LESS_THAN_EQUAL"] = "LESS_THAN_EQUAL";
    BinaryOp["SHIFT_LEFT"] = "SHIFT_LEFT";
    BinaryOp["SHIFT_RIGHT"] = "SHIFT_RIGHT";
})(BinaryOp = exports.BinaryOp || (exports.BinaryOp = {}));
var UnaryOp;
(function (UnaryOp) {
    UnaryOp["PLUS"] = "PLUS";
    UnaryOp["MINUS"] = "MINUS";
    UnaryOp["NOT"] = "NOT";
    UnaryOp["BIT_NOT"] = "BIT_NOT";
})(UnaryOp = exports.UnaryOp || (exports.UnaryOp = {}));
