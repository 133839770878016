"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextAreaStatusWriter = exports.NopStatusWriter = void 0;
class NopStatusWriter {
    append(_) { }
    clear() { }
}
exports.NopStatusWriter = NopStatusWriter;
class TextAreaStatusWriter {
    constructor(textarea) {
        this.textarea = textarea;
    }
    append(text) {
        this.textarea.value += text;
        this.textarea.scrollTop = this.textarea.scrollHeight;
    }
    clear() {
        this.textarea.value = '';
    }
}
exports.TextAreaStatusWriter = TextAreaStatusWriter;
