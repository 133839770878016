"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WrongNumberOfIndex = exports.WrongNumberOfArgument = exports.DuplicatedMain = exports.MissingMain = exports.NotInALoop = exports.InvalidUnaryOperator = exports.InvalidBinaryOperator = exports.UndefinedSymbol = exports.NotAssignable = exports.CannotAssign = exports.NotAConstant = exports.MissingReturnValue = exports.TypeMismatch = exports.BuiltinRedeclared = exports.MultipleDeclaration = exports.UnexpectedToken = exports.TooManyErrors = exports.MissingClosingQuote = exports.UnexpectedCharacter = exports.ErrorWithPosition = exports.MultiCompileError = exports.CompileError = void 0;
const semantic_1 = require("./semantic");
// TODO: I think CompileErrorItem is not necessary. We can just use `CompileError` only and have a special type to hold
// multiple errors, like `MultiCompileError`.
class CompileError extends Error {
}
exports.CompileError = CompileError;
class MultiCompileError extends CompileError {
    constructor(errors) {
        errors = errors.flatMap((e) => {
            if (!(e instanceof MultiCompileError)) {
                return [e];
            }
            let errors = [e];
            const result = [];
            while (errors.length > 0) {
                const temp = errors.flatMap((e) => e.errors);
                errors = [];
                for (const e of temp) {
                    if (e instanceof MultiCompileError) {
                        errors.push(e);
                    }
                    else {
                        result.push(e);
                    }
                }
            }
            return result;
        });
        super(errors.map((v) => v.message).join('\n'));
        this.errors = errors;
    }
}
exports.MultiCompileError = MultiCompileError;
class ErrorWithPosition extends CompileError {
    constructor(message, position) {
        super(`Error at ${position.toString()}: ${message}`);
    }
}
exports.ErrorWithPosition = ErrorWithPosition;
class UnexpectedCharacter extends ErrorWithPosition {
    constructor(char, position) {
        super(`Unexpected character ${JSON.stringify(char)}`, position);
    }
}
exports.UnexpectedCharacter = UnexpectedCharacter;
class MissingClosingQuote extends ErrorWithPosition {
    constructor(position) {
        super(`Missing closing quote in string literal`, position);
    }
}
exports.MissingClosingQuote = MissingClosingQuote;
class TooManyErrors extends CompileError {
    constructor() {
        super(`Too many errors`);
    }
}
exports.TooManyErrors = TooManyErrors;
class UnexpectedToken extends ErrorWithPosition {
    constructor(expected, found) {
        const expectedMessage = typeof expected === 'string'
            ? expected
            : expected.map((k) => k.toString()).join(', ');
        const message = `Expected ${expectedMessage}. But found a ${found.kind.toString()}`;
        super(message, found.position);
    }
}
exports.UnexpectedToken = UnexpectedToken;
class MultipleDeclaration extends ErrorWithPosition {
    constructor(declaredAt, redeclaredAt) {
        super(`${redeclaredAt.value} is already declared at ${declaredAt.position.toString()}`, redeclaredAt.position);
    }
}
exports.MultipleDeclaration = MultipleDeclaration;
class BuiltinRedeclared extends CompileError {
    constructor(declaredAt) {
        super(`${declaredAt.value} is a builtin function, cannot be redeclared at ${declaredAt.position.toString()}`);
    }
}
exports.BuiltinRedeclared = BuiltinRedeclared;
class TypeMismatch extends ErrorWithPosition {
    constructor(source, expectedType) {
        let expectedStr = '';
        if (typeof expectedType === 'string') {
            expectedStr = expectedType;
        }
        else {
            expectedStr = typeToString(expectedType);
        }
        super(`Type mismatch, expected ${expectedStr} got ${typeToString(source.type)}`, source.position);
    }
}
exports.TypeMismatch = TypeMismatch;
function typeToString(type) {
    switch (type.kind) {
        case semantic_1.TypeKind.VOID:
            return 'void';
        case semantic_1.TypeKind.INTEGER:
            return 'integer';
        case semantic_1.TypeKind.REAL:
            return 'real';
        case semantic_1.TypeKind.BOOLEAN:
            return 'boolean';
        case semantic_1.TypeKind.STRING:
            return 'string';
        case semantic_1.TypeKind.BYTE:
            return 'byte';
        case semantic_1.TypeKind.ARRAY:
            return `array[${type.dimension
                .map((v) => v.toString())
                .join(',')}] of ${typeToString(type.elementType)}`;
        case semantic_1.TypeKind.FUNCTION:
            return 'function';
    }
}
class MissingReturnValue extends ErrorWithPosition {
    constructor(returnToken) {
        // TODO: use proper error message
        super('Missing return value', returnToken.position);
    }
}
exports.MissingReturnValue = MissingReturnValue;
class NotAConstant extends ErrorWithPosition {
    constructor(source) {
        super('Not a compile-time constant expression', source.position);
    }
}
exports.NotAConstant = NotAConstant;
class CannotAssign extends ErrorWithPosition {
    constructor(source, target) {
        // TODO: use proper error message
        super('Cannot assign source to target_type', source.position);
    }
}
exports.CannotAssign = CannotAssign;
class NotAssignable extends ErrorWithPosition {
    constructor(receiver) {
        // TODO: use proper error message
        super('receievr is not assignable', receiver.position);
    }
}
exports.NotAssignable = NotAssignable;
class UndefinedSymbol extends ErrorWithPosition {
    constructor(token) {
        super(`Undefined symbol ${token.value}`, token.position);
    }
}
exports.UndefinedSymbol = UndefinedSymbol;
class InvalidBinaryOperator extends ErrorWithPosition {
    constructor(a, op, b) {
        // TODO: use proper error message
        super(`Cannot perform ${op.kind} operation with a and b`, a.position);
    }
}
exports.InvalidBinaryOperator = InvalidBinaryOperator;
class InvalidUnaryOperator extends ErrorWithPosition {
    constructor(value, op) {
        // TODO: use proper error message
        super(`Cannot perform ${op.kind.toString()} operation with value_type`, value.position);
    }
}
exports.InvalidUnaryOperator = InvalidUnaryOperator;
class NotInALoop extends ErrorWithPosition {
    constructor(control) {
        super('Not in a loop', control.position);
    }
}
exports.NotInALoop = NotInALoop;
class MissingMain extends CompileError {
    constructor() {
        super('Missing main in the program');
    }
}
exports.MissingMain = MissingMain;
class DuplicatedMain extends ErrorWithPosition {
    constructor(declared, redeclared) {
        super(`Main program is already declared at ${declared.position.toString()}`, redeclared.position);
    }
}
exports.DuplicatedMain = DuplicatedMain;
class WrongNumberOfArgument extends ErrorWithPosition {
    constructor(expr, expected) {
        super(`Wrong number of arguments. Expected ${expected}, got ${expr.arguments.values.length}`, expr.openBrac.position);
    }
}
exports.WrongNumberOfArgument = WrongNumberOfArgument;
class WrongNumberOfIndex extends ErrorWithPosition {
    constructor(expr, expected) {
        super(`Wrong number of index. Expected ${expected}, got ${expr.index.values.length}`, expr.openSquare.position);
    }
}
exports.WrongNumberOfIndex = WrongNumberOfIndex;
