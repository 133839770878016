"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CanvasDisplayer = exports.NopDisplayer = void 0;
class NopDisplayer {
    drawRect(x, y, width, height, strokeColor, bgColor) { }
    getWidth() {
        return 0;
    }
    getHeigh() {
        return 0;
    }
    onKeyDown(f) { }
    onMouseMove(f) { }
    onMouseClick(f) { }
    start() { }
    stop() { }
    clearAndReset() { }
}
exports.NopDisplayer = NopDisplayer;
class CanvasDisplayer {
    constructor(canvas) {
        this.canvasKeydownHandler = (ev) => {
            this.keydownHandler(ev.key);
        };
        this.canvasMouseMoveHandler = (ev) => {
            this.mouseMoveHandler(ev.offsetX, ev.offsetY);
        };
        this.canvasMouseClickHandler = (ev) => {
            this.mouseClickHandler(ev.offsetX, ev.offsetY);
        };
        this.isStarted = false;
        const context = canvas.getContext('2d');
        if (context == null) {
            throw new Error('cannot create 2d context from canvas');
        }
        this.context = context;
        this.keydownHandler = (_) => { };
        this.mouseMoveHandler = (_, __) => { };
        this.mouseClickHandler = (_, __) => { };
    }
    drawRect(x, y, width, height, strokeColor, bgColor) {
        this.context.fillStyle = bgColor;
        this.context.fillRect(x, y, width, height);
        this.context.strokeStyle = strokeColor;
        this.context.strokeRect(x, y, width, height);
    }
    getWidth() {
        return this.context.canvas.width;
    }
    getHeigh() {
        return this.context.canvas.height;
    }
    onKeyDown(f) {
        this.keydownHandler = f;
        if (this.isStarted) {
            this.context.canvas.addEventListener('keydown', this.canvasKeydownHandler);
        }
    }
    onMouseMove(f) {
        this.mouseMoveHandler = f;
        if (this.isStarted) {
            this.context.canvas.addEventListener('mousemove', this.canvasMouseMoveHandler);
        }
    }
    onMouseClick(f) {
        this.mouseClickHandler = f;
        if (this.isStarted) {
            this.context.canvas.addEventListener('click', this.canvasMouseClickHandler);
        }
    }
    start() {
        this.context.canvas.addEventListener('keydown', this.canvasKeydownHandler);
        this.context.canvas.addEventListener('mousemove', this.canvasMouseMoveHandler);
        this.context.canvas.addEventListener('click', this.canvasMouseClickHandler);
        this.isStarted = true;
    }
    stop() {
        this.context.canvas.removeEventListener('keydown', this.canvasKeydownHandler);
        this.context.canvas.removeEventListener('mousemove', this.canvasMouseMoveHandler);
        this.context.canvas.removeEventListener('click', this.canvasMouseClickHandler);
        this.isStarted = false;
    }
    clearAndReset() {
        this.context.clearRect(0, 0, this.getWidth(), this.getHeigh());
    }
}
exports.CanvasDisplayer = CanvasDisplayer;
