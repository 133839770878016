"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.compile = void 0;
const analyzer_1 = require("./analyzer");
const parser_1 = require("./parser");
const scanner_1 = require("./scanner");
function compile(sourceCode) {
    const tokens = (0, scanner_1.scan)(sourceCode);
    const ast = (0, parser_1.parse)(tokens);
    const program = (0, analyzer_1.analyze)(ast);
    return program;
}
exports.compile = compile;
