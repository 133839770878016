"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.apis = void 0;
const semantic_1 = require("./semantic");
const drawRect = {
    name: 'draw_rect',
    type: {
        kind: semantic_1.TypeKind.FUNCTION,
        arguments: [
            { kind: semantic_1.TypeKind.INTEGER },
            { kind: semantic_1.TypeKind.INTEGER },
            { kind: semantic_1.TypeKind.INTEGER },
            { kind: semantic_1.TypeKind.INTEGER },
            { kind: semantic_1.TypeKind.STRING },
            { kind: semantic_1.TypeKind.STRING }, // bg color like #000
        ],
        return: { kind: semantic_1.TypeKind.VOID },
    },
    arguments: ['x', 'y', 'width', 'height', 'stroke', 'fill'],
};
const getWidth = {
    name: 'get_width',
    type: {
        kind: semantic_1.TypeKind.FUNCTION,
        arguments: [],
        return: { kind: semantic_1.TypeKind.INTEGER },
    },
    arguments: ['width'],
};
const getHeight = {
    name: 'get_height',
    type: {
        kind: semantic_1.TypeKind.FUNCTION,
        arguments: [],
        return: { kind: semantic_1.TypeKind.INTEGER },
    },
    arguments: ['height'],
};
const output = {
    name: 'output',
    type: {
        kind: semantic_1.TypeKind.FUNCTION,
        arguments: [{ kind: semantic_1.TypeKind.STRING }],
        return: { kind: semantic_1.TypeKind.VOID },
    },
    arguments: ['message'],
};
const registerOnUpdate = {
    name: 'register_on_update',
    type: {
        kind: semantic_1.TypeKind.FUNCTION,
        arguments: [
            {
                kind: semantic_1.TypeKind.FUNCTION,
                arguments: [],
                return: { kind: semantic_1.TypeKind.VOID },
            },
        ],
        return: { kind: semantic_1.TypeKind.VOID },
    },
    arguments: ['callback'],
};
const registerOnKeydown = {
    name: 'register_on_keydown',
    type: {
        kind: semantic_1.TypeKind.FUNCTION,
        arguments: [
            {
                kind: semantic_1.TypeKind.FUNCTION,
                arguments: [{ kind: semantic_1.TypeKind.STRING }],
                return: { kind: semantic_1.TypeKind.VOID },
            },
        ],
        return: { kind: semantic_1.TypeKind.VOID },
    },
    arguments: ['callback'],
};
const registerOnClick = {
    name: 'register_on_mouse_click',
    type: {
        kind: semantic_1.TypeKind.FUNCTION,
        arguments: [
            {
                kind: semantic_1.TypeKind.FUNCTION,
                arguments: [{ kind: semantic_1.TypeKind.INTEGER }, { kind: semantic_1.TypeKind.INTEGER }],
                return: { kind: semantic_1.TypeKind.VOID },
            },
        ],
        return: { kind: semantic_1.TypeKind.VOID },
    },
    arguments: ['callback'],
};
const registerOnMouseMove = {
    name: 'register_on_mouse_move',
    type: {
        kind: semantic_1.TypeKind.FUNCTION,
        arguments: [
            {
                kind: semantic_1.TypeKind.FUNCTION,
                arguments: [{ kind: semantic_1.TypeKind.INTEGER }, { kind: semantic_1.TypeKind.INTEGER }],
                return: { kind: semantic_1.TypeKind.VOID },
            },
        ],
        return: { kind: semantic_1.TypeKind.VOID },
    },
    arguments: ['callback'],
};
const unixTimeMillis = {
    name: 'unix_time_millis',
    type: {
        kind: semantic_1.TypeKind.FUNCTION,
        arguments: [],
        return: { kind: semantic_1.TypeKind.INTEGER },
    },
    arguments: [],
};
const systemTimeMillis = {
    name: 'system_time_millis',
    type: {
        kind: semantic_1.TypeKind.FUNCTION,
        arguments: [],
        return: { kind: semantic_1.TypeKind.INTEGER },
    },
    arguments: [],
};
exports.apis = {
    functions: [
        drawRect,
        getWidth,
        getHeight,
        output,
        registerOnUpdate,
        registerOnKeydown,
        registerOnClick,
        registerOnMouseMove,
        unixTimeMillis,
        systemTimeMillis,
    ],
};
