"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrimitiveTypes = exports.TokenKind = exports.Position = exports.Token = void 0;
class Token {
    constructor(kind, value, pos) {
        this.kind = kind;
        this.value = value;
        this.position = pos;
    }
    encode() {
        return (this.kind.toString() +
            (!KEYWORD.has(this.kind) ? `(${this.value.toString()})` : ''));
    }
}
exports.Token = Token;
class Position {
    constructor(line, col) {
        this.line = line;
        this.col = col;
    }
    toString() {
        return `${this.line}:${this.col}`;
    }
}
exports.Position = Position;
var TokenKind;
(function (TokenKind) {
    TokenKind["EOF"] = "EOF";
    TokenKind["INVALID"] = "INVALID";
    TokenKind["COMMENT"] = "COMMENT";
    TokenKind["VAR"] = "VAR";
    TokenKind["TYPE"] = "TYPE";
    TokenKind["AS"] = "AS";
    TokenKind["IDENTIFIER"] = "IDENTIFIER";
    TokenKind["FUNCTION"] = "FUNCTION";
    TokenKind["COMMA"] = "COMMA";
    TokenKind["COLON"] = "COLON";
    TokenKind["ARROW"] = "ARROW";
    TokenKind["SEMICOLON"] = "SEMICOLON";
    TokenKind["BEGIN"] = "BEGIN";
    TokenKind["END"] = "END";
    TokenKind["OPEN_SQUARE"] = "OPEN_SQUARE";
    TokenKind["CLOSE_SQUARE"] = "CLOSE_SQUARE";
    TokenKind["OPEN_BRAC"] = "OPEN_BRAC";
    TokenKind["CLOSE_BRAC"] = "CLOSE_BRAC";
    TokenKind["INTEGER_LITERAL"] = "INTEGER_LITERAL";
    TokenKind["REAL_LITERAL"] = "REAL_LITERAL";
    TokenKind["STRING_LITERAL"] = "STRING_LITERAL";
    TokenKind["TRUE"] = "TRUE";
    TokenKind["FALSE"] = "FALSE";
    TokenKind["ASSIGN"] = "ASSIGN";
    TokenKind["PLUS"] = "PLUS";
    TokenKind["MINUS"] = "MINUS";
    TokenKind["MULTIPLY"] = "MULTIPLY";
    TokenKind["DIV"] = "DIV";
    TokenKind["MOD"] = "MOD";
    TokenKind["BIT_OR"] = "BIT_OR";
    TokenKind["BIT_AND"] = "BIT_AND";
    TokenKind["BIT_XOR"] = "BIT_XOR";
    TokenKind["BIT_NOT"] = "BIT_NOT";
    TokenKind["SHIFT_LEFT"] = "SHIFT_LEFT";
    TokenKind["SHIFT_RIGHT"] = "SHIFT_RIGHT";
    TokenKind["AND"] = "AND";
    TokenKind["NOT"] = "NOT";
    TokenKind["OR"] = "OR";
    TokenKind["EQUAL"] = "EQUAL";
    TokenKind["NOT_EQUAL"] = "NOT_EQUAL";
    TokenKind["GREATER_THAN"] = "GREATER_THAN";
    TokenKind["GREATER_THAN_EQUAL"] = "GREATER_THAN_EQUAL";
    TokenKind["LESS_THAN"] = "LESS_THAN";
    TokenKind["LESS_THAN_EQUAL"] = "LESS_THAN_EQUAL";
    TokenKind["IF"] = "IF";
    TokenKind["THEN"] = "THEN";
    TokenKind["ELSE"] = "ELSE";
    TokenKind["WHILE"] = "WHILE";
    TokenKind["DO"] = "DO";
    TokenKind["CONTINUE"] = "CONTINUE";
    TokenKind["BREAK"] = "BREAK";
    TokenKind["RETURN"] = "RETURN";
    TokenKind["INTEGER"] = "INTEGER";
    TokenKind["STRING"] = "STRING";
    TokenKind["BYTE"] = "BYTE";
    TokenKind["REAL"] = "REAL";
    TokenKind["BOOLEAN"] = "BOOLEAN";
    TokenKind["ARRAY"] = "ARRAY";
    TokenKind["OF"] = "OF";
    TokenKind["STRUCT"] = "STRUCT";
})(TokenKind = exports.TokenKind || (exports.TokenKind = {}));
exports.PrimitiveTypes = [
    TokenKind.INTEGER,
    TokenKind.BYTE,
    TokenKind.REAL,
    TokenKind.BOOLEAN,
    TokenKind.STRING,
];
const KEYWORD = new Set([
    TokenKind.EOF,
    TokenKind.INVALID,
    TokenKind.VAR,
    TokenKind.TYPE,
    TokenKind.STRUCT,
    TokenKind.AS,
    TokenKind.FUNCTION,
    TokenKind.COMMA,
    TokenKind.COLON,
    TokenKind.ARROW,
    TokenKind.SEMICOLON,
    TokenKind.BEGIN,
    TokenKind.END,
    TokenKind.ARRAY,
    TokenKind.OF,
    TokenKind.OPEN_SQUARE,
    TokenKind.CLOSE_SQUARE,
    TokenKind.OPEN_BRAC,
    TokenKind.CLOSE_BRAC,
    TokenKind.TRUE,
    TokenKind.FALSE,
    TokenKind.ASSIGN,
    TokenKind.PLUS,
    TokenKind.MINUS,
    TokenKind.MULTIPLY,
    TokenKind.DIV,
    TokenKind.MOD,
    TokenKind.BIT_OR,
    TokenKind.BIT_AND,
    TokenKind.BIT_XOR,
    TokenKind.BIT_NOT,
    TokenKind.SHIFT_LEFT,
    TokenKind.SHIFT_RIGHT,
    TokenKind.AND,
    TokenKind.NOT,
    TokenKind.OR,
    TokenKind.EQUAL,
    TokenKind.NOT_EQUAL,
    TokenKind.GREATER_THAN,
    TokenKind.GREATER_THAN_EQUAL,
    TokenKind.LESS_THAN,
    TokenKind.LESS_THAN_EQUAL,
    TokenKind.IF,
    TokenKind.THEN,
    TokenKind.ELSE,
    TokenKind.WHILE,
    TokenKind.DO,
    TokenKind.CONTINUE,
    TokenKind.BREAK,
    TokenKind.RETURN,
    TokenKind.INTEGER,
    TokenKind.BYTE,
    TokenKind.REAL,
    TokenKind.BOOLEAN,
]);
