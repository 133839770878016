"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseStatement = exports.TokenIterator = exports.parse = void 0;
const ast_1 = require("./ast");
const config_1 = require("./config");
const errors_1 = require("./errors");
const tokens_1 = require("./tokens");
// TODO: implement non stack-safe recursive with depth limit. Do not use exception as it will be tedious to work with
// non recursive technique.
function parse(tokens) {
    const tokenIter = new TokenIterator(tokens);
    const [root, error] = parseRoot(tokenIter);
    if (error !== undefined) {
        throw error;
    }
    return root;
}
exports.parse = parse;
function ok(val) {
    return [val, undefined];
}
function err(err) {
    return [undefined, err];
}
class TokenIterator {
    constructor(tokens) {
        this.tokens = tokens.filter((tok) => tok.kind !== tokens_1.TokenKind.COMMENT);
        this.index = 0;
    }
    token() {
        if (this.index < this.tokens.length) {
            return this.tokens[this.index];
        }
        if (this.tokens.length === 0) {
            return new tokens_1.Token(tokens_1.TokenKind.EOF, '', new tokens_1.Position(0, 0));
        }
        return new tokens_1.Token(tokens_1.TokenKind.EOF, '', this.tokens[this.tokens.length - 1].position);
    }
    kind() {
        return this.token().kind;
    }
    take() {
        const token = this.token();
        this.next();
        return token;
    }
    next() {
        if (this.index < this.tokens.length) {
            this.index++;
        }
    }
}
exports.TokenIterator = TokenIterator;
function parseRoot(tokens) {
    const variables = [];
    const functions = [];
    const errors = [];
    let main;
    while (tokens.kind() !== tokens_1.TokenKind.EOF) {
        if (tokens.kind() === tokens_1.TokenKind.SEMICOLON) {
            tokens.next();
        }
        else if (tokens.kind() === tokens_1.TokenKind.VAR) {
            const [varNode, error] = parseVariable(tokens);
            if (error !== undefined) {
                errors.push(error);
                skipUntil(tokens, [tokens_1.TokenKind.SEMICOLON]);
                if (errors.length > config_1.MAX_ERRORS) {
                    break;
                }
                continue;
            }
            variables.push(varNode);
        }
        else if (tokens.kind() === tokens_1.TokenKind.FUNCTION) {
            const [functionNode, error] = parseFunction(tokens);
            if (error !== undefined) {
                errors.push(error);
                skipUntil(tokens, [tokens_1.TokenKind.SEMICOLON]);
                if (errors.length > config_1.MAX_ERRORS) {
                    break;
                }
                continue;
            }
            functions.push(functionNode);
        }
        else if (tokens.kind() === tokens_1.TokenKind.BEGIN) {
            if (main !== undefined) {
                errors.push(new errors_1.DuplicatedMain(main.body.begin, tokens.token()));
                skipUntil(tokens, [tokens_1.TokenKind.SEMICOLON]);
                if (errors.length > config_1.MAX_ERRORS) {
                    break;
                }
            }
            const [body, error] = parseBlockStatement(tokens);
            if (error !== undefined) {
                errors.push(error);
                skipUntil(tokens, [tokens_1.TokenKind.SEMICOLON]);
                if (errors.length > config_1.MAX_ERRORS) {
                    break;
                }
            }
            else {
                main = { body };
            }
        }
        else {
            errors.push(new errors_1.UnexpectedToken('declaration', tokens.token()));
            skipUntil(tokens, [tokens_1.TokenKind.SEMICOLON]);
            if (errors.length > config_1.MAX_ERRORS) {
                break;
            }
        }
    }
    if (errors.length > 0) {
        return err(new errors_1.MultiCompileError(errors));
    }
    return ok({ variables, functions, main });
}
function parseVariable(tokens) {
    if (tokens.kind() !== tokens_1.TokenKind.VAR) {
        return err(new errors_1.UnexpectedToken(tokens_1.TokenKind.VAR, tokens.token()));
    }
    const varToken = tokens.take();
    if (tokens.kind() !== tokens_1.TokenKind.IDENTIFIER) {
        return err(new errors_1.UnexpectedToken(tokens_1.TokenKind.IDENTIFIER, tokens.token()));
    }
    const varName = tokens.take();
    if (tokens.kind() === tokens_1.TokenKind.COLON) {
        const colon = tokens.take();
        const [typeExpr, error] = parseTypeExpr(tokens);
        if (error !== undefined) {
            return err(error);
        }
        let assignToken;
        let value;
        if (tokens.kind() === tokens_1.TokenKind.ASSIGN) {
            assignToken = tokens.take();
            const [v, error] = parseExpr(tokens);
            if (error !== undefined) {
                return err(error);
            }
            value = v;
        }
        if (tokens.kind() !== tokens_1.TokenKind.SEMICOLON) {
            return err(new errors_1.UnexpectedToken([tokens_1.TokenKind.SEMICOLON], tokens.token()));
        }
        return ok({
            var: varToken,
            name: varName,
            colon,
            type: typeExpr,
            assign: assignToken,
            value,
        });
    }
    else if (tokens.kind() === tokens_1.TokenKind.ASSIGN) {
        const assignToken = tokens.take();
        const [value, error] = parseExpr(tokens);
        if (error !== undefined) {
            return err(error);
        }
        if (tokens.kind() !== tokens_1.TokenKind.SEMICOLON) {
            return err(new errors_1.UnexpectedToken([tokens_1.TokenKind.SEMICOLON], tokens.token()));
        }
        return ok({
            var: varToken,
            name: varName,
            assign: assignToken,
            value,
        });
    }
    else {
        return err(new errors_1.UnexpectedToken([tokens_1.TokenKind.COLON, tokens_1.TokenKind.ASSIGN], tokens.token()));
    }
}
function parseFunction(tokens) {
    if (tokens.kind() !== tokens_1.TokenKind.FUNCTION) {
        return err(new errors_1.UnexpectedToken([tokens_1.TokenKind.FUNCTION], tokens.token()));
    }
    const functionTok = tokens.take();
    if (tokens.kind() !== tokens_1.TokenKind.IDENTIFIER) {
        return err(new errors_1.UnexpectedToken([tokens_1.TokenKind.IDENTIFIER], tokens.token()));
    }
    const nameTok = tokens.take();
    if (tokens.kind() !== tokens_1.TokenKind.OPEN_BRAC) {
        return err(new errors_1.UnexpectedToken([tokens_1.TokenKind.OPEN_BRAC], tokens.token()));
    }
    const openBracTok = tokens.take();
    const [params, error] = parseParams(tokens);
    if (error !== undefined) {
        return err(error);
    }
    if (tokens.kind() !== tokens_1.TokenKind.CLOSE_BRAC) {
        return err(new errors_1.UnexpectedToken([tokens_1.TokenKind.CLOSE_BRAC], tokens.token()));
    }
    const closeBracTok = tokens.take();
    let arrowTok;
    let returnType;
    if (tokens.kind() === tokens_1.TokenKind.ARROW) {
        arrowTok = tokens.take();
        const [retType, error] = parseTypeExpr(tokens);
        if (error !== undefined) {
            return err(error);
        }
        returnType = retType;
    }
    if (tokens.kind() === tokens_1.TokenKind.SEMICOLON) {
        return ok({
            function: functionTok,
            name: nameTok,
            openBrac: openBracTok,
            params,
            closeBrac: closeBracTok,
            arrow: arrowTok,
            returnType,
        });
    }
    else {
        const [body, error] = parseBlockStatement(tokens);
        if (error !== undefined) {
            return err(error);
        }
        return ok({
            function: functionTok,
            name: nameTok,
            openBrac: openBracTok,
            params,
            closeBrac: closeBracTok,
            arrow: arrowTok,
            returnType,
            body,
        });
    }
}
function parseParams(tokens) {
    const params = [];
    const commas = [];
    if (tokens.kind() === tokens_1.TokenKind.CLOSE_BRAC) {
        return ok({ params, commas });
    }
    while (true) {
        if (tokens.kind() !== tokens_1.TokenKind.IDENTIFIER) {
            return err(new errors_1.UnexpectedToken([tokens_1.TokenKind.IDENTIFIER], tokens.token()));
        }
        const name = tokens.take();
        if (tokens.kind() !== tokens_1.TokenKind.COLON) {
            return err(new errors_1.UnexpectedToken([tokens_1.TokenKind.COLON], tokens.token()));
        }
        const colon = tokens.take();
        const [type, error] = parseTypeExpr(tokens);
        if (error !== undefined) {
            return err(error);
        }
        params.push({ name, colon, type });
        if (tokens.kind() !== tokens_1.TokenKind.COMMA) {
            break;
        }
        tokens.next();
    }
    return ok({ params, commas });
}
function parseStatement(tokens) {
    while (tokens.kind() === tokens_1.TokenKind.SEMICOLON) {
        tokens.next();
    }
    let result;
    const kind = tokens.kind();
    if (kind === tokens_1.TokenKind.BEGIN) {
        result = parseBlockStatement(tokens);
    }
    else if (kind === tokens_1.TokenKind.IF) {
        result = parseIfStatement(tokens);
    }
    else if (kind === tokens_1.TokenKind.WHILE) {
        result = parseWhileStatement(tokens);
    }
    else if (kind === tokens_1.TokenKind.VAR) {
        result = parseVarStatement(tokens);
    }
    else if (kind === tokens_1.TokenKind.RETURN) {
        result = parseReturnStatement(tokens);
    }
    else if (kind === tokens_1.TokenKind.CONTINUE || kind === tokens_1.TokenKind.BREAK) {
        result = parseKeywordStatement(tokens);
    }
    else {
        result = parseAssignStatement(tokens);
    }
    const [stmt, error] = result;
    if (error !== undefined) {
        skipUntil(tokens, [tokens_1.TokenKind.SEMICOLON]);
        return err(error);
    }
    if (tokens.kind() === tokens_1.TokenKind.SEMICOLON) {
        tokens.next();
    }
    return ok(stmt);
}
exports.parseStatement = parseStatement;
function parseBlockStatement(tokens) {
    if (tokens.kind() !== tokens_1.TokenKind.BEGIN) {
        return err(new errors_1.UnexpectedToken([tokens_1.TokenKind.BEGIN], tokens.token()));
    }
    const begin = tokens.take();
    const statements = [];
    const errors = [];
    while (tokens.kind() !== tokens_1.TokenKind.EOF && tokens.kind() !== tokens_1.TokenKind.END) {
        const [stmt, error] = parseStatement(tokens);
        if (error !== undefined) {
            errors.push(error);
            skipUntil(tokens, [tokens_1.TokenKind.SEMICOLON]);
            if (errors.length > config_1.MAX_ERRORS) {
                return err(new errors_1.MultiCompileError(errors));
            }
        }
        else {
            statements.push(stmt);
        }
    }
    if (tokens.kind() !== tokens_1.TokenKind.END) {
        return err(new errors_1.UnexpectedToken([tokens_1.TokenKind.END], tokens.token()));
    }
    const end = tokens.take();
    if (errors.length > 0) {
        return err(new errors_1.MultiCompileError(errors));
    }
    return ok({ kind: ast_1.StatementNodeKind.BLOCK, begin, statements, end });
}
function parseIfStatement(tokens) {
    if (tokens.kind() !== tokens_1.TokenKind.IF) {
        return err(new errors_1.UnexpectedToken([tokens_1.TokenKind.IF], tokens.token()));
    }
    const ifToken = tokens.take();
    const [condition, error] = parseExpr(tokens);
    if (error !== undefined) {
        return err(error);
    }
    if (tokens.kind() !== tokens_1.TokenKind.THEN) {
        return err(new errors_1.UnexpectedToken([tokens_1.TokenKind.THEN], tokens.token()));
    }
    const thenToken = tokens.take();
    const stmt = parseStatement(tokens);
    if (stmt[1] !== undefined) {
        return err(stmt[1]);
    }
    const body = stmt[0];
    if (tokens.kind() !== tokens_1.TokenKind.ELSE) {
        return ok({
            kind: ast_1.StatementNodeKind.IF,
            if: ifToken,
            condition,
            then: thenToken,
            body,
        });
    }
    const elseToken = tokens.take();
    const elseStmt = parseStatement(tokens);
    if (elseStmt[1] !== undefined) {
        return err(elseStmt[1]);
    }
    const elseBody = elseStmt[0];
    return ok({
        kind: ast_1.StatementNodeKind.IF,
        if: ifToken,
        condition,
        then: thenToken,
        body,
        else: elseToken,
        elseBody,
    });
}
function parseWhileStatement(tokens) {
    if (tokens.kind() !== tokens_1.TokenKind.WHILE) {
        return err(new errors_1.UnexpectedToken([tokens_1.TokenKind.WHILE], tokens.token()));
    }
    const whileToken = tokens.take();
    const [condition, error] = parseExpr(tokens);
    if (error !== undefined) {
        return err(error);
    }
    if (tokens.kind() !== tokens_1.TokenKind.DO) {
        return err(new errors_1.UnexpectedToken([tokens_1.TokenKind.DO], tokens.token()));
    }
    const doToken = tokens.take();
    const stmt = parseStatement(tokens);
    if (stmt[1] !== undefined) {
        return err(stmt[1]);
    }
    const body = stmt[0];
    return ok({
        kind: ast_1.StatementNodeKind.WHILE,
        while: whileToken,
        condition,
        do: doToken,
        body,
    });
}
function parseVarStatement(tokens) {
    const [variable, error] = parseVariable(tokens);
    if (error !== undefined) {
        return err(error);
    }
    return ok({ kind: ast_1.StatementNodeKind.VAR, variable });
}
function parseReturnStatement(tokens) {
    if (tokens.kind() !== tokens_1.TokenKind.RETURN) {
        return err(new errors_1.UnexpectedToken([tokens_1.TokenKind.RETURN], tokens.token()));
    }
    const ret = tokens.take();
    if (tokens.kind() === tokens_1.TokenKind.SEMICOLON) {
        return ok({
            kind: ast_1.StatementNodeKind.RETURN,
            return: ret,
        });
    }
    const [value, error] = parseExpr(tokens);
    if (error !== undefined) {
        return err(error);
    }
    return ok({
        kind: ast_1.StatementNodeKind.RETURN,
        return: ret,
        value,
    });
}
function parseAssignStatement(tokens) {
    const [receiver, error] = parseExpr(tokens);
    if (error !== undefined) {
        return err(error);
    }
    if (tokens.kind() !== tokens_1.TokenKind.ASSIGN) {
        return ok({ kind: ast_1.StatementNodeKind.EXPR, expr: receiver });
    }
    const assign = tokens.take();
    const valueResult = parseExpr(tokens);
    if (valueResult[1] !== undefined) {
        return err(valueResult[1]);
    }
    const value = valueResult[0];
    if (tokens.kind() !== tokens_1.TokenKind.SEMICOLON) {
        return err(new errors_1.UnexpectedToken([tokens_1.TokenKind.SEMICOLON], tokens.token()));
    }
    tokens.next();
    return ok({ kind: ast_1.StatementNodeKind.ASSIGN, receiver, assign, value });
}
const keywordTokens = [tokens_1.TokenKind.CONTINUE, tokens_1.TokenKind.BREAK];
function parseKeywordStatement(tokens) {
    if (!keywordTokens.includes(tokens.kind())) {
        return err(new errors_1.UnexpectedToken(keywordTokens, tokens.token()));
    }
    const keyword = tokens.take();
    return ok({
        kind: ast_1.StatementNodeKind.KEYWORD,
        keyword,
    });
}
const primitiveTypes = [
    tokens_1.TokenKind.INTEGER,
    tokens_1.TokenKind.BYTE,
    tokens_1.TokenKind.REAL,
    tokens_1.TokenKind.BOOLEAN,
    tokens_1.TokenKind.STRING,
];
function parseTypeExpr(tokens) {
    if (tokens.kind() === tokens_1.TokenKind.ARRAY) {
        return parseArrayTypeExpr(tokens);
    }
    if (!primitiveTypes.includes(tokens.kind())) {
        return err(new errors_1.UnexpectedToken(primitiveTypes, tokens.token()));
    }
    const typeToken = tokens.take();
    return ok({ kind: ast_1.TypeExprNodeKind.IDENT, type: typeToken });
}
function parseArrayTypeExpr(tokens) {
    if (tokens.kind() !== tokens_1.TokenKind.ARRAY) {
        return err(new errors_1.UnexpectedToken([tokens_1.TokenKind.ARRAY], tokens.token()));
    }
    const arrayToken = tokens.take();
    if (tokens.kind() !== tokens_1.TokenKind.OPEN_SQUARE) {
        return err(new errors_1.UnexpectedToken([tokens_1.TokenKind.OPEN_SQUARE], tokens.token()));
    }
    const openSquare = tokens.take();
    const [commaSeparatedSize, error] = parseCommaSeparatedExpr(tokens);
    if (error !== undefined) {
        return err(error);
    }
    if (tokens.kind() !== tokens_1.TokenKind.CLOSE_SQUARE) {
        return err(new errors_1.UnexpectedToken([tokens_1.TokenKind.CLOSE_SQUARE], tokens.token()));
    }
    const closeSquare = tokens.take();
    if (tokens.kind() !== tokens_1.TokenKind.OF) {
        return err(new errors_1.UnexpectedToken([tokens_1.TokenKind.OF], tokens.token()));
    }
    const ofToken = tokens.take();
    const elementTypeResult = parseTypeExpr(tokens);
    if (elementTypeResult[1] !== undefined) {
        return err(elementTypeResult[1]);
    }
    const elementType = elementTypeResult[0];
    return ok({
        kind: ast_1.TypeExprNodeKind.ARRAY,
        array: arrayToken,
        openSquare,
        dimension: commaSeparatedSize,
        closeSquare,
        of: ofToken,
        elementType,
    });
}
function parseExpr(tokens) {
    return parseBinaryExpr(tokens, operatorPrecedences[0]);
}
const operatorPrecedences = [
    tokens_1.TokenKind.OR,
    tokens_1.TokenKind.AND,
    tokens_1.TokenKind.BIT_OR,
    tokens_1.TokenKind.BIT_XOR,
    tokens_1.TokenKind.BIT_AND,
    tokens_1.TokenKind.EQUAL,
    tokens_1.TokenKind.NOT_EQUAL,
    tokens_1.TokenKind.LESS_THAN,
    tokens_1.TokenKind.LESS_THAN_EQUAL,
    tokens_1.TokenKind.GREATER_THAN,
    tokens_1.TokenKind.GREATER_THAN_EQUAL,
    tokens_1.TokenKind.SHIFT_LEFT,
    tokens_1.TokenKind.SHIFT_RIGHT,
    tokens_1.TokenKind.PLUS,
    tokens_1.TokenKind.MINUS,
    tokens_1.TokenKind.MULTIPLY,
    tokens_1.TokenKind.DIV,
    tokens_1.TokenKind.MOD,
];
function parseBinaryExpr(tokens, op) {
    const i = operatorPrecedences.indexOf(op);
    const [aExpr, error] = i === operatorPrecedences.length - 1
        ? parseArrayIndexExpr(tokens)
        : parseBinaryExpr(tokens, operatorPrecedences[i + 1]);
    if (error !== undefined) {
        return err(error);
    }
    let result = aExpr;
    while (true) {
        if (tokens.kind() !== op) {
            return ok(result);
        }
        const opToken = tokens.take();
        const [bExpr, error] = i === operatorPrecedences.length - 1
            ? parseArrayIndexExpr(tokens)
            : parseBinaryExpr(tokens, operatorPrecedences[i + 1]);
        if (error !== undefined) {
            return err(error);
        }
        result = {
            kind: ast_1.ExprNodeKind.BINARY,
            a: result,
            op: opToken,
            b: bExpr,
        };
    }
}
function parseArrayIndexExpr(tokens) {
    const [arraySource, error] = parseCastExpr(tokens);
    if (error !== undefined) {
        return err(error);
    }
    if (tokens.kind() !== tokens_1.TokenKind.OPEN_SQUARE) {
        return ok(arraySource);
    }
    const openSquare = tokens.take();
    const indexResult = parseCommaSeparatedExpr(tokens);
    if (indexResult[1] !== undefined) {
        return err(indexResult[1]);
    }
    const index = indexResult[0];
    if (tokens.kind() !== tokens_1.TokenKind.CLOSE_SQUARE) {
        return err(new errors_1.UnexpectedToken([tokens_1.TokenKind.CLOSE_SQUARE], tokens.token()));
    }
    const closeSquare = tokens.take();
    return ok({
        kind: ast_1.ExprNodeKind.ARRAY_INDEX,
        array: arraySource,
        openSquare,
        index,
        closeSquare,
    });
}
function parseCastExpr(tokens) {
    const [source, error] = parseUnaryExpr(tokens);
    if (error !== undefined) {
        return err(error);
    }
    if (tokens.kind() !== tokens_1.TokenKind.AS) {
        return ok(source);
    }
    const asToken = tokens.take();
    const typeResult = parseTypeExpr(tokens);
    if (typeResult[1] !== undefined) {
        return err(typeResult[1]);
    }
    const target = typeResult[0];
    return ok({ kind: ast_1.ExprNodeKind.CAST, source, as: asToken, target });
}
const unaryOp = [
    tokens_1.TokenKind.BIT_NOT,
    tokens_1.TokenKind.MINUS,
    tokens_1.TokenKind.PLUS,
    tokens_1.TokenKind.NOT,
];
function parseUnaryExpr(tokens) {
    if (!unaryOp.includes(tokens.kind())) {
        return parseCallExpr(tokens);
    }
    const op = tokens.take();
    const [value, error] = parseCallExpr(tokens);
    if (error !== undefined) {
        return err(error);
    }
    return ok({ kind: ast_1.ExprNodeKind.UNARY, op, value });
}
function parseCallExpr(tokens) {
    const [callee, error] = parsePrimaryExpr(tokens);
    if (error !== undefined) {
        return err(error);
    }
    if (tokens.kind() !== tokens_1.TokenKind.OPEN_BRAC) {
        return ok(callee);
    }
    const openBrac = tokens.take();
    if (tokens.kind() === tokens_1.TokenKind.CLOSE_BRAC) {
        const closeBrac = tokens.take();
        return ok({
            kind: ast_1.ExprNodeKind.CALL,
            callee,
            openBrac,
            arguments: { values: [], commas: [] },
            closeBrac,
        });
    }
    const argsResult = parseCommaSeparatedExpr(tokens);
    if (argsResult[1] !== undefined) {
        return err(argsResult[1]);
    }
    const args = argsResult[0];
    if (tokens.kind() !== tokens_1.TokenKind.CLOSE_BRAC) {
        return err(new errors_1.UnexpectedToken([tokens_1.TokenKind.CLOSE_BRAC], tokens.token()));
    }
    const closeBrac = tokens.take();
    return ok({
        kind: ast_1.ExprNodeKind.CALL,
        callee,
        openBrac,
        arguments: args,
        closeBrac,
    });
}
function parsePrimaryExpr(tokens) {
    if (tokens.kind() === tokens_1.TokenKind.OPEN_BRAC) {
        const openBrac = tokens.take();
        const [value, error] = parseExpr(tokens);
        if (error !== undefined) {
            return err(error);
        }
        if (tokens.kind() !== tokens_1.TokenKind.CLOSE_BRAC) {
            return err(new errors_1.UnexpectedToken([tokens_1.TokenKind.CLOSE_BRAC], tokens.token()));
        }
        const closeBrac = tokens.take();
        return ok({ kind: ast_1.ExprNodeKind.GROUPED, openBrac, value, closeBrac });
    }
    else if (tokens.kind() === tokens_1.TokenKind.OPEN_SQUARE) {
        const openSquare = tokens.take();
        const [value, error] = parseCommaSeparatedExpr(tokens);
        if (error !== undefined) {
            return err(error);
        }
        if (tokens.kind() !== tokens_1.TokenKind.CLOSE_SQUARE) {
            return err(new errors_1.UnexpectedToken([tokens_1.TokenKind.CLOSE_BRAC], tokens.token()));
        }
        const closeSquare = tokens.take();
        return ok({ kind: ast_1.ExprNodeKind.ARRAY_LIT, openSquare, value, closeSquare });
    }
    else if (tokens.kind() === tokens_1.TokenKind.INTEGER_LITERAL) {
        const value = tokens.take();
        return ok({ kind: ast_1.ExprNodeKind.INTEGER_LIT, value });
    }
    else if (tokens.kind() === tokens_1.TokenKind.REAL_LITERAL) {
        const value = tokens.take();
        return ok({ kind: ast_1.ExprNodeKind.REAL_LIT, value });
    }
    else if (tokens.kind() === tokens_1.TokenKind.TRUE ||
        tokens.kind() === tokens_1.TokenKind.FALSE) {
        const value = tokens.take();
        return ok({ kind: ast_1.ExprNodeKind.BOOLEAN_LIT, value });
    }
    else if (tokens.kind() === tokens_1.TokenKind.IDENTIFIER) {
        const name = tokens.take();
        return ok({ kind: ast_1.ExprNodeKind.IDENT, name });
    }
    else if (tokens.kind() === tokens_1.TokenKind.STRING_LITERAL) {
        const value = tokens.take();
        return ok({ kind: ast_1.ExprNodeKind.STRING_LIT, value });
    }
    else {
        throw new errors_1.UnexpectedToken('Expression', tokens.token());
    }
}
function parseCommaSeparatedExpr(tokens) {
    const exprs = [];
    const commas = [];
    while (true) {
        const [expr, error] = parseExpr(tokens);
        if (error !== undefined) {
            return err(error);
        }
        exprs.push(expr);
        if (tokens.kind() !== tokens_1.TokenKind.COMMA) {
            break;
        }
        const commaTok = tokens.take();
        commas.push(commaTok);
    }
    return ok({ values: exprs, commas });
}
function skipUntil(tokens, kinds) {
    while (tokens.kind() !== tokens_1.TokenKind.EOF && !kinds.includes(tokens.kind())) {
        tokens.next();
    }
    tokens.next();
}
